import en from '@/i18n/en';
import zhTw from '@/i18n/zh-tw';
import zhCn from '@/i18n/zh-cn';

const i18nObj = {
  en,
  'zh-tw': zhTw,
  'zh-cn': zhCn,
};

export default i18nObj;
