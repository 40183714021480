<template>
  <div>
    <ul class="content-row">
      <li
        class="content-row__item"
        :class="[`content-row__item--${col.type}`]"
        :style="{ flex: col.width ? 'none' : '1', width: col.width, 'text-align': col.align }"
        v-for="(col, idx2) of columns"
        :key="col.key"
      >

        <template v-if="col.type === 'label'">
          <div class="content-row__item__cell" :class="[{ 'content-row__item__cell--padding-left': idx2 === 0 }]">
            <template v-if="col.render">
              <component
                :is="col.render"
                v-bind="{ data, column: col, idx: idx1, expandStatus }"
                @expand="handleExpand"
              />

            </template>
            <template v-else>
              <span class="content-row__item__text">{{ data[col.key] }}</span>

            </template>
          </div>
        </template>
        <template v-if="col.type === 'checkbox'">
          <div class="content-row__item__checkbox">
            <n-checkbox v-model:checked="isCheck" />
          </div>
        </template>
        <template v-if="col.type === 'action'">
          <img src="@/assets/myMusic/icon_more.svg" />
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  defineComponent, ref, inject, computed,
  watch,
} from 'vue';
import { NCheckbox } from 'naive-ui';

export default defineComponent({
  name: 'ListContentRow',
  components: {
    NCheckbox,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    columns: {
      type: Object,
      default: () => ({}),
    },

    idx1: {
      type: Number,
    },
  },
  setup() {
    const isCheck = ref(false);
    const expandStatus = ref(true);

    const handleExpand = () => {
      expandStatus.value = !expandStatus.value;
    };

    const globalCheckStatus = inject('isCheckAll');
    const checkStatus = computed(() => globalCheckStatus.value);

    watch(checkStatus, (newVal) => {
      console.log('newVal', newVal);
      isCheck.value = checkStatus.value;
    });

    return {
      handleExpand,
      expandStatus,
      isCheck,
      checkStatus,
    };
  },
});
</script>
<style lang="scss" scoped>
.content-row {
  width: 100%;
  height: 52px;
  @include flex();

  &__item {
    @include flex();
    @include padding(20px 0);

    &__text {
      @include font-style($c-white, 16, normal, 0.8px, 26px);
    }

    &__cell {
      width: 100%;

      &--padding-left {
        width: 100%;
      }
    }

    &__checkbox {
      width: 100%;
      @include flex(center);
    }
  }
}

@media screen and (min-width: $pc) {
  .content-row {
    height: 78px;
  }
}
</style>
