<template>
  <div class="c-remove">
    <img class="c-remove__img" src="@/assets/myMusic/icon_remove.svg">
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Remove',
});
</script>
<style lang="scss" scoped>
.c-remove {
  width: 100%;
  @include flex(center);
}
</style>
