<template>
  <div class="c-add-to-favorite function-icon">
    <tooltip label="Add To Favorite">
    <div class="function-icon" @click="addToFavorite">
      <div class="function-icon__img function-icon__img--star"></div>
      <span class="function-icon__label" v-if="label"> 新增至我的最愛 </span>
    </div>
    </tooltip>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Tooltip from '@/components/Global/Tooltip.vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'AddToFavorite',
  components: {
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();

    const addToFavorite = () => {
      console.log('addToFavorite');
    };

    return {
      addToFavorite,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
