<template>
  <div class="c-share-music">
    <div class="action-row">
      <div class="start-time">
        <n-checkbox v-model:checked="startTime.set" label="指定開始處" />
        <div class="start-time__field">

          <!-- 00:00 ~ 99:59 有超過 100分鐘的歌的話 之後再改-->
          <input
            class="start-time__field__text"
            v-model="startTime.time"
            v-maska="{
              mask: 'TT:AB',
              tokens: { T: { pattern: /[0-9]/ }, A: { pattern: /[0-5]/ }, B: { pattern: /[0-9]/ } },
            }"
          />
        </div>
      </div>
      <div class="btn btn--email">
        <mi-button icon="email">
          <span class="btn__text">Email</span>
        </mi-button>
      </div>
      <div class="btn btn--copy-link">
        <mi-button icon="link">
          <span class="btn__text">Copy link</span>
        </mi-button>
      </div>
    </div>
    <p class="err-msg" v-if="errMsg">請輸入正確的時間格式（MM:SS）</p>
    <div class="music-link">
      <input class="music-link__input" v-model="musicLink" />
      <!-- v-model="musicLink" -->
    </div>
  </div>
</template>
<script>
import {
  defineComponent, reactive, computed,
} from 'vue';
import { NCheckbox } from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'ShareMusic',
  components: {
    NCheckbox,
    MiButton,
  },
  setup() {
    const startTime = reactive({
      set: false,
      time: '00:30',
    });

    const domain = reactive('http://pointgroup.com/mat749-1');

    const formatTimeToSecond = (t) => {
      const [mins, seconds] = t.split(':');

      return Number(mins * 60) + Number(seconds);
    };

    const musicLink = computed(() => {
      if (startTime.set) {
        return `${domain}?t=${formatTimeToSecond(startTime.time)}`;
      }

      return domain;
    });

    const errMsg = computed(() => {
      const regExp = /^\d{2}:\d{2}$/;
      console.log('regExp.test(startTime.time)', regExp.test(startTime.time));

      return !regExp.test(startTime.time);
    });

    return {
      startTime,
      musicLink,
      errMsg,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-share-music {
  margin-top: 45px;
  @include padding(0 24px);
}
.action-row {
  @include flex(space-between);
}

.start-time {
  @include flex();

  &__field {
    width: 45px;
    // margin-left: 15px;
    position: relative;

    &::after {
      content: '';
      background-color: $c-assist4;
      @include position(tl, 100%, 0);
      width: 45px;
      height: 1px;
    }

    &__text {
      width: 45px;
      border: 0 transparent;
      outline: 0 transparent;
      text-align: center;
      padding: 0;
      @include font-style($c-black, 14, normal, 0.7px, 26px);
    }
  }
}

.btn {
  &--email {
  }

  &--copy-link {
  }
  &__text {
    margin-left: 12px;
  }

  &__icon {
  }
}

.music-link {
  margin-top: 30px;
  margin-bottom: 20px;
  @include flex();
  @include padding(0 24px);
  height: 40px;
  border-radius: 20px;
  background-color: $c-assist1;

  &__input {
    width: 100%;
    @include font-style($c-assist6, 14, normal, 0.7px, 18px);
    border: 0px transparent;
    outline: 0px transparent;
    background-color: transparent;
  }
}

.err-msg {
  color: $c-error
}
</style>
