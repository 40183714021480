import { defineStore } from 'pinia';

export const useSearchStore = defineStore({
  id: 'search',
  state: () => ({

    includeLabelGroup: {},
    excludeLabelGroup: {},
    includeLabels: [
      // {
      //   label: '樂觀 Optimisitic',
      //   key: 'optimisitic',
      //   type: 'include',
      // },
      // {
      //   label: '無憂無慮 Carefree',
      //   key: 'carefree',
      //   type: 'include',
      // },
      // {
      //   label: '調皮 Playful',
      //   key: 'playful',
      //   type: 'include',
      // },
      // {
      //   label: '悲傷 Sad',
      //   key: 'Sad',
      //   type: 'include',
      // },
      // {
      //   label: '有害的 Harmful',
      //   key: 'Harmful',
      //   type: 'include',
      // },
      // {
      //   label: '謙虛的 Humble',
      //   key: 'Humble',
      //   type: 'include',
      // },
      // {
      //   label: '民歌 FOLK',
      //   key: 'FOLK',
      //   type: 'include',
      // },
      // {
      //   label: '俗氣 KITSCH',
      //   key: 'KITSCH',
      //   type: 'include',
      // },
      // {
      //   label: '流行曲 POP',
      //   key: 'POP',
      //   type: 'include',
      // },
      // {
      //   label: '騷靈 SOUL',
      //   key: 'SOUL',
      //   type: 'include',
      // },
    ],
    excludeLabels: [
      // {
      //   label: '調皮 Playful',
      //   key: 'playful',
      //   type: 'exclude',
      // },
    ],
    allLabels: [],
  }),

  actions: {
    setIncludeLabels(label) {
      this.includeLabels.push({
        ...label,
        type: 'include',
      });
      this.updateAllLabels();
    },
    setExcludeLabels(label) {
      this.excludeLabels.push({
        ...label,
        type: 'exclude',
      });
      this.updateAllLabels();
    },
    setIncludeLabelGroup(groupId, groupDetail) {
      this.includeLabelGroup[groupId] = groupDetail;

      groupDetail.forEach((item) => {
        this.setIncludeLabels(item);
      });
    },
    setExcludeLabelGroup(groupId, groupDetail) {
      console.log('!?', groupId, groupDetail);

      groupDetail.forEach((item) => {
        this.setIncludeLabels(item);
      });
      delete this.includeLabelGroup[groupId];
    },
    updateAllLabels() {
      console.log('?');
      this.allLabels = [...this.includeLabels, ...this.excludeLabels];
    },

  },

});

export const a = '';
