<template>
  <div class="c-title-with-function">
    <div class="c-title-with-function__title">
      <span class="c-title-with-function__title__text">{{ data.title }}</span>
      <span class="c-title-with-function__title__extra">({{ data.titleExtra }})</span>
    </div>
    <div class="c-title-with-function__function">
      <music-function :function-list="['add', 'star', 'download', 'share']" showPlay  ml/>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';

export default defineComponent({
  name: 'TitleWithFunction',
  components: {
    MusicFunction,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-title-with-function {
  width: 100%;
  @include flex();

  &__title {
    @include flex();
    flex: none;
    width: 300px;

    &__text {
      @include font-style($c-white, 16, bold, 0.8px, 26px);
    }

    &__extra {
      @include font-style($c-white, 16, normal, 0.8px, 26px);
    }
  }

  &__function {
    flex: 1;
  }
}
</style>
