<template>
  <div class="c-add-to-favorite function-icon">
    <div class="function-icon" @click="addToFavorite">
      <div class="function-icon__img function-icon__img--star"></div>

      <span class="function-icon__label" v-if="label"> 從我的最愛移除 </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'RemoveFromFavorite',
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();

    const addToFavorite = () => {
      console.log('addToFavorite');
    };

    return {
      addToFavorite,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
