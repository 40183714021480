<template>
  <div class="c-search-keyword-dropdown" :class="{ 'c-search-keyword-dropdown--is-search-pop': isSearchPopMobile }">
    <div class="select-wrap" @click="toggleExpand">
      <input class="select-wrap__text" readonly autocomplete="off" placeholder="搜索歌詞" :value="currOption" />
      <div class="dropdown-icon" :class="{ 'dropdown-icon--active': visible }">
        <div class="dropdown-icon__left"></div>
        <div class="dropdown-icon__right"></div>
      </div>
    </div>

    <div class="mi-select-wrap">
      <n-collapse-transition :show="visible">
        <ul class="mi-select-list" v-click-away="toggleExpand">
          <mi-select-option
            v-for="opt of options"
            :key="opt.value"
            v-bind="opt"
            :currOption="currOption"
            @handleOption="handleOption"
          />
        </ul>
      </n-collapse-transition>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NCollapseTransition } from 'naive-ui';
import MiSelectOption from '@/components/Global/Select/SelectOption.vue';

export default defineComponent({
  name: 'SearchKeywordDropdown',
  components: {
    NCollapseTransition,
    MiSelectOption,
  },
  props: {
    isSearchPopMobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const currOption = ref('');
    const visible = ref(false);
    // const isExpand = ref(false);

    const options = [
      {
        label: '選項一',
        value: '選項一',
      },
      {
        label: '選項二',
        value: '選項二',
      },
    ];

    const toggleExpand = () => {
      visible.value = !visible.value;
      console.log('visible', visible.value);
    };

    const handleOption = (opt) => {
      console.log(opt);
      currOption.value = opt;

      toggleExpand();
    };

    return {
      visible,
      // isExpand,
      // handleExpand,
      toggleExpand,
      handleOption,
      options,
      currOption,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-search-keyword-dropdown {
  position: relative;

  &--is-search-pop {
    .select-wrap {
      width: 100%;
      border-right: 0px;

      &__text {
        width: 100px;
        color: $c-white;
      }
    }
  }
}

.select-wrap {
  @include flex(space-between);
  width: 125px;
  cursor: pointer;
  position: relative;
  border-right: 1px solid rgba($c-assist3, 0.5);

  &__text {
    width: 100px;
    border: 0px;
    outline: 0px;
    background-color: transparent;
    @include font-style($c-assist6, 18, normal, 0.9px, 24px);
  }
}

.dropdown-icon {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  margin-right: 15px;

  &__left {
    width: 7px;
    height: 2px;
    background-color: $c-main;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 3px), -50%) rotate(-30deg);
    // left: 3px;
    transition: 0.3s;
  }

  &__right {
    width: 7px;
    height: 2px;
    background-color: $c-main;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 3px), -50%) rotate(30deg);
    // left: -3px;
    transition: 0.3s;
  }

  &--active {
    .dropdown-icon {
      &__left {
        transform: translate(calc(-50% + 3px), -50%) rotate(30deg);
      }

      &__right {
        transform: translate(calc(-50% - 3px), -50%) rotate(-30deg);
      }
    }
  }
}

.mi-select-wrap {
  width: 100%;
  @include position(tl, 100%, 0);
  z-index: 100;
}

.mi-select-list {
  width: 100%;
  background-color: $c-white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px #00000029;
  width: 100%;

  &__list {
    width: 100%;
  }
}
</style>
