<template>
  <div class="c-track-info" :class="{'c-track-info--padding': padding}">
    <div class="c-track-info__content">
      <p class="info-desc">
        Self-assured and lyrically confident. A fast tempo track with mysterious, shimmering synths and complex, jerky
        beats. A cavalier sense of egotistical bluster plays into the narrative of the competitive spirit chasing the
        prize. A real champion in the making. Self-assured and lyrically confident. A fast tempo track with mysterious,
        shimmering synths and complex, jerky beats.
      </p>

      <div class="info-detail">
        <ul class="data-list">
          <li class="data-list__item" v-for="item of data" :key="item.title">
            <p class="data-list__item__title">{{ item.title }}</p>
            <p class="data-list__item__text" v-for="d of item.data" :key="d">{{ d }}</p>
          </li>
        </ul>

        <div class="copy">
          <img class="copy__img" src="@/assets/searchResults/icon_copy.svg" @click="doCopy" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TrackInfo',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    padding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      message: 'test Messag123',
    };
  },
  methods: {
    doCopy() {
      this.$copyText(this.message).then(
        (e) => {
          console.log(e);
        },
        (e) => {
          console.log(e);
        },
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.c-track-info {

  &--padding {
    padding: 20px;
  }

  &__content {
    width: 100%;
    @include flex(flex-start, flex-start, column);
  }
}
.info-desc {
  @include font-style($c-black, 14, 400, 0.7px, 20px);
  @include padding(0 0 24px 0);
  margin-bottom: 24px;
  border-bottom: 1px solid $c-assist7;
}

.info-detail {
  width: 100%;
  @include flex(flex-start, flex-start);
}

.data-list {
  flex: 1;

  &__item {
    flex: 1;

    & + & {
      margin-top: 24px;
    }

    &__title {
      @include font-style($c-assist3, 14, bold, 0.7px, 26px);
      margin-bottom: 8px;
    }

    &__text {
      @include font-style($c-black, 14, 600, 0.7px, 26px);
    }
  }
}

.copy {
  flex: none;
  width: 18px;

  &__img {
    width: 100%;
    cursor: pointer;
  }
}

@media screen and (min-width: $tablet) {

  .c-track-info {
    @include padding(0 20px);

    &__content {
      flex-direction: row;
    }
  }

  .info-desc {
    display: none;
  }

  .data-list {
    width: 100%;
    @include flex(flex-start, flex-start);

    &__item {

      & + & {
        margin-top: 0px;
      }
    }
  }
}
</style>
