<template>
  <div class="c-my-music-list-content">
    <div class="c-my-music-list-content__content">
      <ul class="content-list">
        <li class="content-list__item" v-for="(data, idx1) of contentData" :key="data.key">
          <list-content-row :columns="columns" :data="data" :idx1="idx1" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import ListContentRow from '@/components/Global/MusicPlayer/VersionQueueList/ListContentRow.vue';

export default defineComponent({
  name: 'MyMusicListContent',
  components: {
    ListContentRow,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    contentData: {
      type: Array,
      default: () => [],
    },
    onExpand: {},
  },
  setup() {
    return {};
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-content {
  &__content {

  }
}

.content-list {
  &__item {
    box-shadow: 0px 0px 10px rgba($c-black, 0.05);
    border-radius: 5px;

    & + & {
      border-top: 1px solid $c-assist8;
    }
  }
}
</style>
