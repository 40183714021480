<template>
  <div class="c-music-function">
    <div class="show-empty-expand" v-if="showExpand"></div>
    <div class="expand" v-if="showDetailExpand" @click="handleExpand('detail')">
      <img
        class="expand__img"
        :class="{ 'expand__img--active': expandDetailStatus }"
        src="@/assets/icon/icon_info_open.svg"
      />
    </div>
    <div class="expand" v-if="showMusicExpand" @click="handleExpand('music')">
      <img
        class="expand__img"
        :class="{ 'expand__img--active': expandMusicStatus }"
        src="@/assets/icon/icon_info_open.svg"
      />
    </div>
    <div class="expand" v-if="showInnerMusicExpand" @click="handleInnerMusicExpand">
      <img
        class="expand__img"
        :class="{ 'expand__img--active': expandInnerMusicStatus }"
        src="@/assets/icon/icon_info_open.svg"
      />
    </div>

    <ul class="function-list" :class="{ 'function-list--margin-left': ml }">
      <!-- (!showDetailExpand && !showMusicExpand && !showInnerMusicExpand) && showPlay -->
      <template v-for="icon of iconLists" :key="icon">
        <li class="function-list__item function-list__item--play" v-if="icon === 'play'">
          <play-pause type="playlist" />
        </li>

        <li class="function-list__item" v-else>
          <add-to-playlists v-if="icon === 'add'" />
          <add-to-favorite v-if="icon === 'star'" />
          <download v-if="icon === 'download'" />
          <note v-if="icon === 'note'" />
          <share v-if="icon === 'share'" />
          <share-playlists v-if="icon === 'share-playlists'" />
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import { defineComponent, computed, inject } from 'vue';
import PlayPause from '@/components/Global/MusicPlayer/Functions/PlayPause.vue';
import AddToPlaylists from '@/components/Global/MusicPlayer/Functions/AddToPlaylists.vue';
import AddToFavorite from '@/components/Global/MusicPlayer/Functions/AddToFavorite.vue';
import Note from '@/components/Global/MusicPlayer/Functions/Note.vue';
import Share from '@/components/Global/MusicPlayer/Functions/Share.vue';
import SharePlaylists from '@/components/Global/MusicPlayer/Functions/SharePlaylists.vue';
import Download from '@/components/Global/MusicPlayer/Functions/Download.vue';

export default defineComponent({
  name: 'MusicFunction',
  components: {
    PlayPause,
    AddToPlaylists,
    AddToFavorite,
    Note,
    Share,
    Download,
    SharePlaylists,
  },
  props: {
    // 展開 Detail (第二層)
    showDetailExpand: {
      type: Boolean,
      default: false,
    },
    // 展開 Music (版本與剪輯、曲目資訊、歌詞、分軌 ...)
    showMusicExpand: {
      type: Boolean,
      default: false,
    },
    // 嵌套在 detail 內的 Music
    showInnerMusicExpand: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    showPlay: {
      type: Boolean,
      default: true,
    },
    functionList: {
      type: Array,
      default: () => ['add', 'star', 'download', 'note', 'share'],
    },
    ml: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // const modelStatus = reactive({
    //   share: false,
    //   add: false,
    //   note: false,
    // });

    const iconLists = computed(() => {
      if (props.showPlay) {
        return ['play', ...props.functionList];
      }

      return props.functionList;
    });

    const expandDetailStatus = computed(() => {
      try {
        return inject('expandStatus')().detail;
      } catch {
        return false;
      }
    });
    const expandMusicStatus = computed(() => {
      try {
        return inject('expandStatus')().music;
      } catch {
        return false;
      }
    });
    const handleExpand = inject('handleExpand', () => {});

    const expandInnerMusicStatus = computed(() => {
      try {
        return inject('expandInnerMusicStatus', () => {})();
      } catch (e) {
        return false;
      }
    });
    const handleInnerMusicExpand = inject('handleInnerMusicExpand', () => {});

    // const handleClick = (key) => {
    //   modelStatus[key] = true;
    // };

    return {
      // modelStatus,
      // handleClick,
      iconLists,
      handleExpand,
      expandDetailStatus,
      expandMusicStatus,
      expandInnerMusicStatus,
      handleInnerMusicExpand,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-music-function {
  color: $c-white;
  position: relative;
  @include flex();
}

.function-list {
  @include flex();

  &--margin-left {
    margin-left: 30px;
  }

  &__item {
    & + & {
      margin-left: 12px;
    }

    &--play {
      margin-right: 24px;
    }

  }
}

.expand {
  vertical-align: bottom;
  cursor: pointer;
  transition: 0.3s;
  width: 45px;
  height: 45px;
  @include flex();

  &__img {
    transform: rotate(0deg);
    transition: 0.3s;

    &--active {
      transform: rotate(-180deg);
    }
  }
}

.show-empty-expand {
  width: 45px;
}
</style>
