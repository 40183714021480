<template>
  <div class="c-add-playlists-btn" v-if="data.visible">
  <tooltip label="Add To Playlist">
    <mi-button icon="add" @click="addToPlaylist">
      <!-- <span class="add-text">Add playlist</span> -->
    </mi-button>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout title="新增至歌單" decoLine>
        <add />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NModal } from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import Add from '@/components/Global/Popup/Add.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'AddPlaylistsBtn',
  components: {
    MiButton,
    NModal,
    ModelBaseLayout,
    Add,
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const modelStatus = ref(false);

    const addToPlaylist = () => {
      modelStatus.value = true;
      console.log('addToPlaylist');
    };

    return {
      modelStatus,
      addToPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-add-playlists {
  & + & {
    margin-left: 12px;
  }
}
.add-icon {
  width: 15px;
}

.add-text {
  margin-left: 9px;

}
</style>
