<template>
  <div class="c-share function-icon">
    <tooltip label="Share Track">
      <div class="function-icon" @click="share">
        <div class="function-icon__img function-icon__img--share"></div>
        <span class="function-icon__label" v-if="label"> 分享 </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout title="分享曲目" decoLine>
        <share-music />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import ShareMusic from '@/components/Global/Popup/ShareMusic.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'Share',
  components: {
    NModal,
    ModelBaseLayout,
    ShareMusic,
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    const modelStatus = ref(false);

    const share = () => {
      console.log('share');
      modelStatus.value = true;
      // musicPlayerStore.handleNext();
    };

    return {
      modelStatus,
      share,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
