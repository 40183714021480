<template>
  <div class="ly-menu-search-pop">
    <img class="ly-menu-search-pop__close" src="@/assets/searchResults/icon_popup_close.svg" @click="closePop" />

    <div class="ly-menu-search-pop__content">
      <search-input isSearchPopMobile showFullMatch/>

      <div class="tags show-tablet">
        <p class="tags__title">
          <span class="tags__title__text">Tags</span>
          <span class="tags__title__count">(12+)</span>
        </p>
        <div class="tags__wrap">
          <label-item v-for="l of labelWith" :label="l" :key="l" isSearchPop />
        </div>
      </div>

      <div class="tabs">
        <search-tabs :tabs="searchTabs" v-model:activeTab="activeTab" @handleTab="handleTab" isSearchPop />
      </div>

      <div class="search-result">
        <ul class="search-box-list">
          <li class="search-box-list__item" v-for="i of 20" :key="i">
            <div class="search-box-list__item__box">
              <search-box-item />
            </div>
          </li>
        </ul>
      </div>

      <div class="btn-wrap">
        <red-circle-button label="了解更多" label-color="#ffffff" label-bg="#000000" @click="toSearchPage" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import SearchInput from '@/components/SearchResult/SearchInput.vue';
import LabelItem from '@/components/Global/LabelItem.vue';
import SearchBoxItem from '@/components/Global/SearchBoxItem.vue';
import RedCircleButton from '@/components/Home/RedCircleButton.vue';
import SearchTabs from '@/components/SearchResult/SearchTabs.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MenuSearchPop',
  components: {
    SearchInput,
    LabelItem,
    SearchBoxItem,
    RedCircleButton,
    SearchTabs,
  },
  emits: ['update:showSearchPop'],
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();

    const labelWith = [
      '勵志 Inspirational',
      '孩子般 Childlike',
      '快樂/正面 Happy/Positive',
      '振奮 Uplifting',
      '期望 Expectation',
      '溫暖 Warm',
      '滿足 Satisfied',
      '無憂無慮 Carefree',
    ];
    const activeTab = ref('tracks');

    const searchTabs = ref([
      {
        label: 'TRACKS',
        key: 'tracks',
        total: 12332,
      },
      {
        label: 'ALBUMS',
        key: 'albums',
        total: 10,
      },
      {
        label: 'PLAYLISTS',
        key: 'playlists',
        total: 5,
      },
    ]);
    const closePop = () => {
      siteStore.toggleShowSearchPop(false);
    };

    const handleTab = (key) => {
      activeTab.value = key;
    };

    const toSearchPage = () => {
      router.push({
        name: 'search',
        query: {
          tab: activeTab.value,
        },
      });
      closePop();
    };

    return {
      activeTab,
      labelWith,
      searchTabs,
      handleTab,
      closePop,
      toSearchPage,
    };
  },
});
</script>
<style lang="scss" scoped>
.ly-menu-search-pop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-color: $c-black;
  z-index: $zi-search-pop;

  &__close {
    @include position(tr, 15px, 0px);
    cursor: pointer;
  }

  &__content {
    @include max-width(900);
    margin-top: 90px;
  }
}

.tags {
  margin-top: 50px;
  &__title {
    &__text {
      @include font-style($c-white, 20, normal, 1px, 26px);
    }

    &__count {
      margin-left: 3px;
      @include font-style($c-assist4, 12, normal, 0.6px, 16px);
    }
  }

  &__wrap {
    margin-top: 17px;
  }
}

.tabs {
  margin-top: 50px;
}

.search-result {
  margin-top: 27px;
}

.search-box-list {
  width: 100%;
  @include flex(flex-start, flex-start, column);
  margin: 0 -10px;

  &__item {
    flex: none;
    width: 100%;
    margin-top: 24px;

    &+& {
      margin-top: 20px;
    }

    &__box {
      @include padding(0 10px);
    }
  }
}

.btn-wrap {
  margin-top: 45px;
  margin-bottom: 45px;
  @include flex(center);
}

.show-tablet {
  display: none;
}

@media screen and (min-width: $tablet) {

   .show-mobile {
    display: none;
  }

  .show-tablet {
    display: block;
  }
  .ly-menu-search-pop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $c-black;
    z-index: $zi-search-pop;

    &__close {
      @include position(tr, 15px, 90px);
      cursor: pointer;
    }

    &__content {
      @include max-width(900);
      margin-top: 90px;
    }
  }

  .tags {
    margin-top: 50px;
    &__title {
      &__text {
        @include font-style($c-white, 20, normal, 1px, 26px);
      }

      &__count {
        margin-left: 3px;
        @include font-style($c-assist4, 12, normal, 0.6px, 16px);
      }
    }

    &__wrap {
      margin-top: 17px;
    }
  }

  .tabs {
    margin-top: 50px;
  }

  .search-result {
    margin-top: 27px;
  }

  .search-box-list {
    width: 100%;
    @include flex(flex-start, flex-start, row);
    flex-wrap: wrap;
    margin: 0 -10px;

    &__item {
      flex: none;
      width: 25%;
      margin-top: 24px;

      &:first-child,
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: 0px;
      }

      &__box {
        @include padding(0 10px);
      }
    }
  }

  .btn-wrap {
    margin-top: 45px;
    @include flex(center);
  }
}
</style>
