export default [
  {
    path: '/my-music',
    component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
    meta: {
      title: 'my-music',
      key: 'my-music',
    },
    children: [
      {
        path: 'playlists',
        name: 'my-music-playlists',

        components: {
          default: () => import(/* webpackChunkName: "MyMusic" */ '@/views/MyMusic/Playlists.vue'),
          personalHeader: () => import(/* webpackChunkName: "PersonalHeader" */ '@/layout/PersonalHeader.vue'),
        },
        meta: {
          title: '所有歌單',
          layout: {
            header: 'my-music',
          },
        },
      },
      {
        path: 'search-history',
        name: 'my-music-search-history',

        components: {
          default: () => import(/* webpackChunkName: "Search" */ '@/views/MyMusic/SearchHistory.vue'),
          personalHeader: () => import(/* webpackChunkName: "PersonalHeader" */ '@/layout/PersonalHeader.vue'),
        },
        meta: {
          title: '搜尋紀錄',
          layout: {
            header: 'my-music',
          },
        },
      },
      {
        path: 'play-history',
        name: 'my-music-play-history',

        components: {
          default: () => import(/* webpackChunkName: "PlayHistory" */ '@/views/MyMusic/PlayHistory.vue'),
          personalHeader: () => import(/* webpackChunkName: "PersonalHeader" */ '@/layout/PersonalHeader.vue'),
        },
        meta: {
          title: '播放紀錄',
          layout: {
            header: 'my-music',
          },
        },
      },
      {
        path: 'download-history',
        name: 'my-music-download-history',

        components: {
          default: () => import(/* webpackChunkName: "Download" */ '@/views/MyMusic/DownloadHistory.vue'),
          personalHeader: () => import(/* webpackChunkName: "PersonalHeader" */ '@/layout/PersonalHeader.vue'),
        },
        meta: {
          title: '下載紀錄',
          layout: {
            header: 'my-music',
          },
        },
      },
      {
        path: 'my-note',
        name: 'my-music-my-note',

        components: {
          default: () => import(/* webpackChunkName: "MyNote" */ '@/views/MyMusic/MyNote.vue'),
          personalHeader: () => import(/* webpackChunkName: "PersonalHeader" */ '@/layout/PersonalHeader.vue'),
        },
        meta: {
          title: '我的筆記',
          layout: {
            header: 'my-music',
          },
        },
      },
      {
        path: 'preview-tool',
        name: 'my-music-preview-tool',
        components: {
          default: () => import(/* webpackChunkName: "PreviewTool" */ '@/views/MyMusic/PreviewTool.vue'),
          personalHeader: () => import(/* webpackChunkName: "PersonalHeader" */ '@/layout/PersonalHeader.vue'),
        },
        meta: {
          title: '影音結合體驗',
          layout: {
            header: 'my-music',
          },
        },
      },
    ],
  },
];
