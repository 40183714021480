<template>
  <div class="c-add-to-playlist function-icon">
    <div class="function-icon" @click="addToPlaylist">
      <div class="function-icon__img function-icon__img--menu-move"></div>
      <span class="function-icon__label" v-if="label"> 搬移至歌單 </span>

      <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
        <model-base-layout title="搬移至歌單" decoLine>
          <!-- <add /> -->
        </model-base-layout>
      </n-modal>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
// import Add from '@/components/Global/Popup/Add.vue';

export default defineComponent({
  name: 'MoveToPlaylist',
  components: {
    NModal,
    ModelBaseLayout,
    // Add,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modelStatus = ref(false);
    // const musicPlayerStore = useMusicPlayerStore();

    const addToPlaylist = () => {
      modelStatus.value = true;
      console.log('addToPlaylist');
    };

    return {
      modelStatus,
      addToPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
