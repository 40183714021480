<template>
  <div class="c-mi-button" :class="`c-mi-button--${type}`">
    <button class="mi-button" :disabled="disabled" @click="onClick">
      <div class="c-mi-button__icon" :class="`c-mi-button__icon--${icon}`" v-if="icon"></div>
      <slot></slot>
    </button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MiButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'normal',
    },
    icon: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const onClick = (e) => {
      emit('click', e);
    };

    return {
      onClick,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mi-button {
  &--normal {
    .mi-button {
      border: 1px transparent solid;
      background-color: $c-main;
      color: $c-white;

      &:hover {
        border: 1px $c-main solid;
        color: $c-main;
        background-color: transparent;
      }
    }
  }

  &--border {
    .mi-button {
      border: 1px solid $c-main;
      color: $c-main;
      background-color: $c-white;
    }
  }

  &--border-white {
    .mi-button {
      border: 1px solid $c-white;
      color: $c-white;
      background-color: transparent;
    }
  }

  &__icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    background-position: center;

    &--email {
      background-image: url('~@/assets/searchResults/icon_popup_share_email.svg');
    }

    &--link {
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      background-image: url('~@/assets/searchResults/icon_popup_share_link.svg');
    }

    &--download {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      background-image: url('~@/assets/feedbackImg/icon_download.svg');
      // background-image: url('~@/assets/icon/icon_download.svg');
    }

    &--add {
      width: 16px;
      height: 16px;
      background-size: 16px 16px;
      background-image: url('~@/assets/feedbackImg/icon_add.svg');
      // background-image: url('~@/assets/icon/icon_add.svg');
    }
  }

  &:hover {
    .c-mi-button__icon {
      &--email {
        background-image: url('~@/assets/searchResults/icon_popup_share_email_active.svg');
      }

      &--link {
        background-image: url('~@/assets/searchResults/icon_popup_share_link_active.svg');
      }
      &--download {
        background-image: url('~@/assets/feedbackImg/icon_download_active.svg');
        // background-image: url('~@/assets/icon/icon_download_active.svg');
      }

      &--add {
        background-image: url('~@/assets/feedbackImg/icon_add_active.svg');
        // background-image: url('~@/assets/icon/icon_add.svg');
      }
    }
  }
}
.mi-button {
  width: auto;
  height: 40px;
  @include padding(0 15px);
  border-radius: 20px;
  outline: 0px transparent;
  @include font-style($c-white, 14, 400, 0.7px);
  cursor: pointer;
  @include inline-flex(center, center);
  transition: 0.3s;
}
</style>
