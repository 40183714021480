<template>
  <div
    class="c-mobile-music-player"
    :style="{
      'background-image': `url('${musicInfo.albumCoverUrl}')`,
    }"
  >
    <div class="c-mobile-music-player__content">
      <div class="player">
        <div class="player__row player__row--top">
          <div class="top__down-arrow">
            <img
              class="top__down-arrow__icon"
              src="@/assets/icon/icon_expand_white.svg"
              @click="closeMobileMusicPlayer"
            />
          </div>
          <div class="top__to-album">
            <p class="top__to-album__text">Go to album</p>
            <img class="top__to-album__arrow-icon" src="@/assets/mobile/icon_go-to-album.svg" />
          </div>
          <div class="top__functions" @click="toggleAlbumFunction">
            <img class="top__functions__icon" src="@/assets/myMusic/icon_more_white.svg" />
          </div>
        </div>
        <div class="player__row player__row--cover">
          <img class="cover-img" :src="musicInfo.albumCoverUrl" />
          <!-- <img class="cover-img" src="@/assets/icon/icon_arrow.svg" /> -->
        </div>
        <div class="player__row player__row--album-info">
          <p class="album-info__song-name">{{ musicInfo.songName }} - {{ musicInfo.singer }}</p>
          <p class="album-info__id">
            {{ musicInfo.creator }}
          </p>
        </div>
        <div class="player__row player__row--music-progress">
          <music-progress style-type="mobileMusicPlayer" :ml="false" />
        </div>
        <div class="player__row player__row--music-control">
          <div class="music-control__wrap">
            <div class="music-control__wrap__icon">
              <add-to-favorite />
            </div>
            <div class="music-control__wrap__icon">
              <prev />
            </div>
            <div class="music-control__wrap__icon">
              <play-pause />
            </div>
            <div class="music-control__wrap__icon">
              <next />
            </div>
            <div class="music-control__wrap__icon">
              <note />
            </div>
          </div>
        </div>
        <div class="player__row player__row--music-info">
          <mobile-music-info />
        </div>
      </div>
    </div>

    <mobile-function v-model:showFunction="showFunction" />
  </div>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
import { useMusicPlayerStore } from '@/store/musicPlayer';
import { useSiteStore } from '@/store/site';
import MusicProgress from '@/components/Global/MusicPlayer/MusicProgress.vue';
import PlayPause from '@/components/Global/MusicPlayer/Functions/PlayPause.vue';
import Prev from '@/components/Global/MusicPlayer/Functions/Prev.vue';
import Next from '@/components/Global/MusicPlayer/Functions/Next.vue';
import AddToFavorite from '@/components/Global/MusicPlayer/Functions/AddToFavorite.vue';
import Note from '@/components/Global/MusicPlayer/Functions/Note.vue';
import MobileMusicInfo from '@/components/Global/MusicPlayer/MobilMusicInfo/index.vue';
import MobileFunction from '@/components/Global/MusicPlayer/MobileFunction.vue';

export default defineComponent({
  name: 'MobileMusicPlayer',
  components: {
    MobileMusicInfo,
    MusicProgress,
    PlayPause,
    Next,
    Prev,
    AddToFavorite,
    Note,
    MobileFunction,
  },
  setup() {
    const siteStore = useSiteStore();
    const musicPlayerStore = useMusicPlayerStore();
    const showFunction = ref(false);
    const toggleAlbumFunction = () => {
      showFunction.value = !showFunction.value;
    };

    const closeMobileMusicPlayer = () => {
      siteStore.toggleShowMobileMusicPlayer(false);
    };

    return {
      musicInfo: computed(() => musicPlayerStore.info),
      showFunction,
      toggleAlbumFunction,
      closeMobileMusicPlayer,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mobile-music-player {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zi-mobile-player;
  background-size: 150%;
  background-position: center;

  &__content {
    height: 100%;
    @include flex(flex-start, flex-start, column);
    backdrop-filter: blur(30px);
  }
}

.player {
  flex: 1;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  &__row {
    @include flex(center, center, column);

    &--top {
      flex: none;
      height: 10%;
      width: 100%;
      @include flex(space-between);
      @include padding(0 30px);
    }

    &--cover {
      // flex: none;
      height: calc(55% - 60px);

      flex: 1;
      @include padding(0 40px);
      // margin-top: 20px;
    }

    &--album-info {
      flex: none;
      height: 15%;
      text-align: center;
      @include padding(0 40px);
    }

    &--music-progress {
      flex: none;
      height: 10%;
      width: 100%;
      @include padding(0 40px);
    }

    &--music-control {
      flex: none;
      height: 10%;
      width: 100%;
      @include padding(0 40px);
    }

    &--music-info {
      flex: none;
      width: 100%;
      height: 60px;
    }
  }
}

.top {
  &__down-arrow {
    @include flex(center);
    &__icon {
    }
  }

  &__to-album {
    @include flex();

    &__text {
      @include font-style(rgba($c-white, 0.6), 14, 400, 0, 18px);
    }

    &__arrow-icon {
      margin-left: 4px;
    }
  }

  &__functions {
    &__icon {
      width: 16px;
    }
  }
}

.cover-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.album-info {
  &__song-name {
    width: 100%;
    @include font-style($c-white, 24, 700, 0, 45px);
    white-space: nowrap;
    overflow: auto;

    &::-webkit-scrollbar {
      height: 0;
    }
  }

  &__id {
    margin-top: 8px;
    @include font-style($c-white, 16, 700, 0, 22px);
  }
}

.music-control {
  width: 100%;

  &__wrap {
    width: 100%;
    @include flex(space-between);

    &__icon {
    }
  }
}

@media screen and (min-width: $portrait) {
  .player {
    max-width: 67vw;
  }
}

@media screen and (min-width: $tablet) {
  .c-mobile-music-player {
    display: none;
  }
}
</style>
