<template>
  <div class="c-time-length">
    {{data.length}}
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TimeLength',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

  },
});
</script>
<style lang="scss" scoped>
.c-time-length {
  @include font-style($c-assist4, 14, 400, 0.8px, 26px);

}

@media screen and (min-width: $sm-pc) {
  .c-time-length {
    color: $c-black

}

}

</style>
