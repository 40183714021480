<template>
  <div class="c-search-box-item">
    <img class="c-search-box-item__cover" src="@/assets/explore/playlists_img.jpg" />
    <div class="c-search-box-item__info">
      <p class="c-search-box-item__info__name">Guiding You</p>
      <p class="c-search-box-item__info__id">LOS630-1</p>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchBoxItem',
});
</script>
<style lang="scss" scoped>

.c-search-box-item {
  @include flex(flex-start, space-between, row);
  width: 100%;

  &__cover {
    width: 70px;
    height: 70px;
  }

  &__info {
    margin-left: 16px;
    @include flex(center, flex-start, column);

    &__name {
      @include font-style($c-white, 16, bold, 0.8px, 26px);
    }

    &__id {
      @include font-style($c-assist2, 14, normal, 0.8px, 18px);
    }
  }
}

</style>
