<template>
  <div class="c-add">
    <div class="playlist">
      <ul class="playlist-list">
        <li class="playlist-list__item" v-for="p of mockPlaylist" :key="p.key">
          <div class="item-box">
            <p class="item-box__title">{{ p.title }}</p>
            <img class="item-box__icon" src="@/assets/searchResults/icon_popup_playlist_add.svg" />
          </div>
        </li>
      </ul>
    </div>
    <div class="create-field">
      <div class="create-field__box">
        <input class="create-field__box__input" placeholder="New Playlist Title" />
        <button class="add-btn">
          <img class="add-btn__icon" src="@/assets/searchResults/icon_popup_playlist_add_2.svg" />
          <span class="add-btn__text">Add</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MoveToPlaylists',
  setup() {
    const mockPlaylist = [
      {
        key: 'happy',
        title: '難過的時候聽了就開心囉',
      },
      {
        key: 'favorite',
        title: '這是我最喜歡的歌單',
      },
      {
        key: 'playlist1',
        title: '歌單一',
      },
      {
        key: 'favorite2',
        title: '歌單二',
      },
      {
        key: 'playlist1',
        title: '歌單三',
      },
      {
        key: 'playlist4',
        title: '歌單四',
      },
      {
        key: 'playlist5',
        title: '歌單五',
      },
    ];

    return {
      mockPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
.playlist {
  height: 300px;
  overflow: auto;
}
.playlist-list {
  &__item {
    @include padding(0 24px);
    @include flex();
    height: 55px;

    &:hover {
      background-color: $c-assist1;

      .item-box__icon {
        display: inline-block;
      }
    }
  }
}

.item-box {
  width: 100%;
  @include flex();

  &__title {
    flex: 1;
    @include font-style($c-assist6, 16, normal, 0.8px, 22px);
  }

  &__icon {
    display: none;
    flex: none;
    width: auto;
    margin-left: 10px;
  }
}

.create-field {
  @include padding(0 24px);
  margin: 0 24px;
  border-top: 1px solid $c-assist7;

  &__box {
    margin-top: 20px;
    @include flex();
    @include padding(0px 24px);
    height: 40px;
    border-radius: 20px;
    background-color: $c-assist1;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;
    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;

  &__icon {
  }

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}
</style>
