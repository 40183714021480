<template>
  <div
    class="c-mobile-music-info"
    :class="{
      'c-mobile-music-info--active': expand,
    }"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <div class="c-mobile-music-info__content">
      <div class="tabs">
        <div
          class="tabs__box"
          v-for="(tab, idx) of tabsConfig"
          :key="tab.label"
          @click="(e) => handleOffset(e, tab.offset)"
        >
          <p class="tabs__box__label" :class="{ 'tabs__box__label--active': offset === idx }">{{ tab.label }}</p>
        </div>
        <div class="curr-tab-bar" :style="{ transform: `translateX(${offset * 100}%)` }"></div>
      </div>

      <div class="content-wrap">

        <versions v-if="offset === 0" />
        <queue v-if="offset === 1" />
        <track-info :data="trackInfoMockData" v-if="offset === 2" />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import Queue from '@/components/Global/MusicPlayer/MobilMusicInfo/Queue.vue';
import Versions from '@/components/Global/MusicPlayer/MobilMusicInfo/Versions.vue';
import TrackInfo from '@/components/MyMusic/MyMusicList/ListDetail/MusicDetail/TrackInfo.vue';

export default defineComponent({
  name: 'MobileMusicInfo',
  components: {
    Versions,
    Queue,
    TrackInfo,
  },
  setup() {
    const expand = ref(false);
    const toggleExpand = (status) => {
      expand.value = status;
    };
    const offset = ref(0);
    const recordTouchStart = ref({});

    const tabsConfig = [
      {
        offset: 0,
        label: 'Versions',
      },
      {
        offset: 1,
        label: 'Queue',
      },
      {
        offset: 2,
        label: 'Info',
      },
    ];

    const trackInfoMockData = [
      {
        title: 'Album',
        data: ['Beyond The Clouds'],
      },
      {
        title: 'Composer',
        data: ['Abbie Lathe [ PRS ] ', 'Al Lethbridge [ PRS ]'],
      },
      {
        title: 'Publisher',
        data: ['BBC Production Music [ PRS ]'],
      },
      {
        title: 'Labels',
        data: ['BBC Production Music'],
      },
    ];

    const handleOffset = (e, offsetValue) => {
      if (expand.value) {
        // 若打開狀態時才阻擋，避免點選 tab 仍關閉
        e.stopPropagation();
        offset.value = offsetValue;
      }
    };

    const handleTouchStart = (e) => {
      const touch = e.changedTouches[0];

      recordTouchStart.value = {
        x: touch.pageX,
        y: touch.pageY,
      };
    };

    const handleTouchEnd = (e) => {
      const touch = e.changedTouches[0];

      const toucheEndRecord = {
        x: touch.pageX,
        y: touch.pageY,
      };

      const moveDist = recordTouchStart.value.y - toucheEndRecord.y;

      if (moveDist > 50) {
        toggleExpand(true);
      }

      if (moveDist < -50) {
        toggleExpand(false);
      }
    };

    return {
      expand,
      toggleExpand,
      tabsConfig,
      offset,
      handleOffset,
      handleTouchStart,
      handleTouchEnd,
      trackInfoMockData,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mobile-music-info {
  width: 100%;
  height: calc(100% - 60px);
  background-color: rgba($c-white, 0.8);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  transition: 0.3s;
  position: fixed;
  top: calc(100% - 60px);
  @include padding(0 20px 20px);

  &--active {
    background-color: rgba($c-white, 1);
    top: 60px;

    .c-mobile-music-info__content {
      &::after {
        background-color: $c-assist17;
      }
    }

    .tabs {
      &__box {
        &__label {
          color: $c-assist3;

          &--active {
            color: $c-black;
          }
        }
      }
    }
  }

  &__content {
    height: 100%;
    width: 100%;
    position: relative;
    @include flex(flex-start, flex-start, column);

    &::after {
      content: '';
      width: 30px;
      height: 4px;
      border-radius: 2px;
      background-color: $c-white;
      @include position(tl, 5px, 50%);
      transform: translateX(-50%);
    }
  }
}

.tabs {
 flex: none;
 height: 60px;

  width: 100%;
  @include padding(22px 0 16px);
  @include flex();

  position: relative;

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    display: inline-block;
    background-color: $c-assist7;
    @include position(tl, calc(100% + 1px), 0);
  }

  &__box {
    flex: 1;
    text-align: center;

    &__label {
      @include font-style($c-white, 16, 0, 0, 22px);
    }
  }
}

.curr-tab-bar {
  @include position(tl, 100%, 0);
  background-color: $c-main;
  width: 33%;
  height: 3px;
  display: inline-block;
  transition: 0.4s;
  z-index: 10;
}

.content-wrap {
  flex: 1;
  margin: 20px 0;
  width: 100%;
  overflow: auto;
}
</style>
