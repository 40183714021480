<template>
  <div class="c-search-tabs" :class="{ 'c-search-tabs--is-search-pop': isSearchPop }">
    <div class="label" v-for="tab of tabs" :key="tab.label" @click="handleActiveTabs(tab.key)">
      <div class="label__box" :class="{ 'label__box--active': tab.key === activeTab }">
        <p class="label__box__text">{{ tab.label }}</p>
        <img class="label__box__deco" src="@/assets/icon/highlight_3.png" />
      </div>

      <span class="label__total" v-if="tab.total">({{ tab.total }})</span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
// import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';

export default defineComponent({
  name: 'SearchLabel',
  components: {
    // RedDecorateTitle,
  },
  emits: ['handleTab'],
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    activeTab: {
      type: String,
      default: '',
    },
    isSearchPop: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const handleActiveTabs = (key) => {
      emit('handleTab', key);
    };

    return {
      handleActiveTabs,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-search-tabs {
  display: inline-block;
  @include flex();

  &--is-search-pop {
    .label__box {
      &--active {
        .label__box__text {
          color: $c-white;
        }
      }
    }
  }
}

.label {
  @include flex();
  cursor: pointer;

  & + & {
    margin-left: 20px;
  }

  &__box {
    position: relative;

    &--active {
      .label__box__text {
        color: $c-black;
      }

      .label__box__deco {
        opacity: 1;
      }
    }

    &__text {
      @include font-style($c-assist3, 18, bold, 1px, 27px);
      position: relative;
      z-index: 10;
    }

    &__deco {
      @include position(br, 0px, 4px);
        transition: 0.3s;
        opacity: 0;
    }
  }

  &__total {
    display: none;

  }
}

@media screen and (min-width: $tablet) {
  .label {
    & + & {
      margin-left: 40px;
    }

    &__box {
      &__text {
        @include font-size(20);
      }

    }

&__total {
    display: inline-block;
    margin-left: 4px;
    @include font-style($c-assist3, 12, normal, 0.6px, 15px);
  }
  }

}
</style>
