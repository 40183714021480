<template>
  <div class="c-music-progress" :class="{ 'c-music-progress--margin-left': ml }">
    <div
      class="music-progress"
      :class="{
        'music-progress--mobile-music-player': styleType === 'mobileMusicPlayer',
      }"
    >
      <p class="music-progress__current-time"
      :class="{'music-progress__current-time--preview': styleType === 'preview'}"
       v-if="styleType !== 'mobileMusicPlayer'">
        {{ musicControll.currentTime }}
      </p>
      <div
        class="music-progress__wrap"
        :class="{
          'music-progress__wrap--mobile-music-player': styleType === 'mobileMusicPlayer',
        }"
        ref="progressBarEle"
        @click="calcProgressBarOffset"
        @mousedown="handleMouseDown"
        @mouseover="handleMouseOver"
        @mouseup="handlelMouseUp"
      >
        <div class="progress-wave__box" v-show="['preview','full', 'mobileMusicPlayer'].includes(styleType)">
          <div
            class="progress-wave__box__pass"
            :class="{'progress-wave__box__pass--preview': styleType === 'preview'}"
            :style="{
              'clip-path': `polygon(${musicControll.progressPersentage}% 0%, 100% 0%, 100% 100%, ${musicControll.progressPersentage}% 100%)`,
            }"
          ></div>

          <div
            class="progress-wave__box__remaining"
            :style="{
              'clip-path': `polygon(0% 0%, ${musicControll.progressPersentage}% 0%, ${musicControll.progressPersentage}% 100%, 0% 100%)`,
            }"
          ></div>
        </div>

        <div class="progress-wrap" v-show="styleType === 'simple'">
          <div class="progress-static"></div>
          <div class="progress-line" :style="{ width: `${musicControll.progressPersentage}%` }">
            <div class="progress-line__dot"></div>
          </div>
        </div>
      </div>
      <p class="music-progress__total-time"
      :class="{'music-progress__total-time--preview': styleType === 'preview'}"
      v-if="styleType !== 'mobileMusicPlayer'">{{ musicControll.totalTime }}</p>
    </div>

    <div class="mobile-music-player-music-progress-time" v-show="styleType === 'mobileMusicPlayer'">
      <p class="mobile-music-player-music-progress-time__current-time">
        {{ musicControll.currentTime }}
      </p>
      <p class="mobile-music-player-music-progress-time__total-time">
        {{ musicControll.totalTime }}
      </p>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'MusicProgress',
  props: {
    styleType: {
      type: String,
      default: '',
    },
    ml: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const progressBarEle = ref(null);
    const isMouseDown = ref(false);
    const musicPlayerStore = useMusicPlayerStore();

    const calcProgressBarOffset = (e) => {
      // 點擊位置與左邊界的 x 距離
      const { clientX } = e;
      // progressBar 資訊
      const eleInfoBox = progressBarEle.value.getBoundingClientRect();

      // 進度條與左邊界的距離
      const leftOffset = progressBarEle.value.getBoundingClientRect().left;
      // 進度條的長度
      const progressBarLength = eleInfoBox.width;
      // 點擊位置在進度條的百分比
      const clickPersentage = (clientX - leftOffset) / progressBarLength;

      // currentSongProgress.value = `${clickPersentage * 100}`;

      musicPlayerStore.$patch({
        controll: {
          progressPersentage: `${clickPersentage * 100}`,
        },
      });
    };

    const handleMouseDown = (e) => {
      isMouseDown.value = true;
      calcProgressBarOffset(e);
    };

    const handleMouseOver = (e) => {
      // 滑鼠壓著的情況下移動
      if (isMouseDown.value) {
        calcProgressBarOffset(e);
      }
    };

    const handlelMouseUp = () => {
      isMouseDown.value = false;
    };

    return {
      calcProgressBarOffset,
      progressBarEle,
      // currentSongProgress,
      handleMouseDown,
      handlelMouseUp,
      handleMouseOver,
      musicControll: computed(() => musicPlayerStore.controll),
    };
  },
});
</script>
<style lang="scss" scoped>
.c-music-progress {
  width: 100%;
  background-size: cover;
  background-position: center;

  &--margin-left {
    margin-left: 30px;
  }
}
.music-progress {
  @include flex(center, center);
  flex: 1;

  &--mobile-music-player {
    .music-progress__wrap {
      margin: 0;
    }

    .progress-wave__box__remaining {
      min-height: 20px;
      height: 3vh;
    }

    .progress-wave__box__pass {
      min-height: 20px;
      height: 3vh;

    }
  }

  &__current-time {
    @include font-style($c-white, 14);
    flex: none;

    &--preview {
      color: $c-assist4;
      padding-right: 22px;
    }
  }

  &__wrap {
    flex: 1;
    margin: 0 8px;
  }

  &__total-time {
    @include font-style($c-white, 14);
    flex: none;
    width: auto;
    text-align: right;

    &--preview {
      color: $c-assist4;
      padding-left: 22px;
    }
  }
}

.progress-wave {
  &__box {
    width: 100%;
    position: relative;
    left: -1;
    // background-color: $c-assist3;
    background-color: transparent;
    height: 56px;

    &__pass {
      // @include position(tl, 0, 0);
      @include position(tl, 0, 0);
      width: 100%;
      height: 56px;
      overflow: hidden;
      vertical-align: middle;
      background-repeat: no-repeat;
      background-color: $c-white;
      -webkit-mask: url('~@/assets/musicPlayer/sound-wave.png') no-repeat;
      -webkit-mask-size: 100% 100%;
      background-size: 100% 100%;

      &--preview {
        background-color: $c-assist7;
      }

      &--mobile-music-player {
        height: 20px;
      }
    }

    &__remaining {
      width: 100%;
      height: 56px;
      @include position(tl, 0, 0);
      background-color: $c-main;
      -webkit-mask: url('~@/assets/musicPlayer/sound-wave.png') no-repeat;
      -webkit-mask-size: 100% 100%;
      background-size: 100% 100%;
    }
  }
}

.progress-wrap {
  @include flex(center);
  height: 24px;
  cursor: pointer;
  position: relative;
}

.progress-static {
  background-color: $c-text1;
  height: 1px;
  width: 100%;
  background-color: #fff;
  @include position(center);
}

.progress-line {
  background-color: $c-main;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  &__dot {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include circle(1px);
    background-color: $c-main;
    transition: 0.3s;
  }
}

.progress-wrap {
  &:hover {
    .progress-line__dot {
      right: -3px;
      @include circle(6px);
    }
  }
}

.mobile-music-player-music-progress-time {
  @include flex(space-between);
  flex: 1;

  &__current-time {
    @include font-style($c-white, 12);
    flex: none;
  }

  &__total-time {
    @include font-style($c-white, 12);
    flex: none;
    width: auto;
    text-align: right;
  }
}
</style>
