<template>
  <div class="c-versions">
    <ul class="row" v-for="d of mockData" :key="d.id">
      <li class="row__item">
        <ul class="versions-info-list">
          <li class="versions-info-list__item versions-info-list__item--version-info">
            <div class="versions-info">
              <version-info :data="d" />
            </div>
          </li>
          <li class="versions-info-list__item versions-info-list__item--length">
            <div class="versions-info">
              <time-length :data="d" />
            </div>
          </li>
          <li class="versions-info-list__item versions-info-list__item--more">
            <div class="versions-info">
              <more :data="d" />
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import VersionInfo from '@/components/Global/MusicPlayer/Fragment/VersionInfo.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import More from '@/components/Global/MusicPlayer/Fragment/More.vue';

export default defineComponent({
  name: 'versions',
  components: {
    VersionInfo,
    TimeLength,
    More,
  },

  setup() {
    const mockData = [
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-1',
      },
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-2',
      },
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-3',
      },
    ];

    return {
      mockData,
    };
  },
});
</script>
<style lang="scss" scoped>
.row {
  &__item {

  }

  & + & {
      margin-top: 20px;
    }
}

.versions-info-list {
  @include flex();

  &__item {
    width: 100%;

    &--version-info {
      flex: 1;
    }

    &--length {
      @include flex(center);
      flex: none;
      width: 45px;
    }

    &--more {
      @include flex(center);
      flex: none;
      width: 45px;
    }
  }
}
</style>
