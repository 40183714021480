export default [
  {
    path: '/explore',
    name: 'explore',
    redirect: '/explore/themes',
    component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
    meta: {
      title: 'explore',
      key: 'explore',
    },
    children: [
      {
        path: ':type',
        name: 'explore-type',
        component: () => import(/* webpackChunkName: "Explore" */ '@/views/Explore/Explore.vue'),
        meta: {
          title: '探索音樂',
          layout: {
            headerStyle: 'black',
          },
        },
      },
      {
        path: 'themes/:category',
        name: 'explore-themes-info',
        component: () => import(/* webpackChunkName: "ThemesCategory" */ '@/views/Explore/ThemesInfo.vue'),
        meta: {
          title: '',
          layout: {

          },
        },
      },
      {
        path: 'moods/:category',
        name: 'explore-moods-info',
        component: () => import(/* webpackChunkName: "MoodsCategory" */ '@/views/Explore/MoodsInfo.vue'),
        meta: {
          title: '',
          layout: {

          },
        },
      },
      {
        path: 'genres/:category',
        name: 'explore-genres-info',
        component: () => import(/* webpackChunkName: "GenresCategory" */ '@/views/Explore/GenresInfo.vue'),
        meta: {
          title: '',
          layout: {

          },
        },
      },
      {
        path: 'albums-info',
        name: 'explore-albums-info',
        component: () => import(/* webpackChunkName: "AlbumsInfo" */ '@/views/Explore/AlbumsInfo.vue'),
        meta: {
          title: '',
          layout: {

          },
        },
      },
      {
        path: 'playlists-info',
        name: 'explore-playlists-info',
        component: () => import(/* webpackChunkName: "PlaylistsInfo" */ '@/views/Explore/PlaylistsInfo.vue'),
        meta: {
          title: '',
          layout: {

          },
        },
      },
      {
        path: 'labels/:category',
        name: 'explore-labels-info',
        component: () => import(/* webpackChunkName: "LabelsInfo" */ '@/views/Explore/LabelsInfo.vue'),
        meta: {
          title: '',
          layout: {

          },
        },
      },
    ],
  },
];
