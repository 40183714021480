<template>
  <div class="c-add-to-playlist function-icon">
    <tooltip label="Add To Playlist">
      <div class="function-icon" @click="addToPlaylist">
        <div class="function-icon__img function-icon__img--add" @click="addToPlaylist"></div>
        <span class="function-icon__label" v-if="label"> 新增至歌單 </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false">
      <model-base-layout title="新增至歌單" decoLine>
        <add />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import Add from '@/components/Global/Popup/Add.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'AddToPlaylist',
  components: {
    NModal,
    ModelBaseLayout,
    Add,
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const modelStatus = ref(false);
    // const musicPlayerStore = useMusicPlayerStore();

    const addToPlaylist = () => {
      modelStatus.value = true;
      console.log('addToPlaylist');
    };

    return {
      modelStatus,
      addToPlaylist,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
