<template>
  <div class="c-note function-icon">
    <tooltip label="Add Note">
      <div class="function-icon" @click="addToNote">
        <div class="function-icon__img function-icon__img--note"></div>
        <span class="function-icon__label" v-if="label"> 新增/查看筆記 </span>
      </div>
    </tooltip>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false" :style="{'max-width': '800px'}">
      <model-base-layout title="新增筆記 - Guiding You">
        <add-note />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';
import { NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import AddNote from '@/components/Global/Popup/AddNote.vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'Note',
  components: {
    NModal,
    ModelBaseLayout,
    AddNote,
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();
    const modelStatus = ref(false);

    const addToNote = () => {
      modelStatus.value = true;
      console.log('addToNote');
      // musicPlayerStore.addToNote();
    };

    return {
      modelStatus,
      addToNote,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
