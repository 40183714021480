import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';
import VueClickAway from 'vue3-click-away';
import VueClipboard from 'vue-clipboard2';
import Maska from 'maska';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import '@/styles/init/index.scss';
import '@/styles/utils/_rwd.scss';
import 'animate.css';
import i18nData from '@/i18n';

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'zh-tw', // set locale
  // locale: "en",
  fallbackLocale: 'zh-tw', // set fallback locale
  messages: i18nData,
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueClickAway)
  .use(VueClipboard)
  .use(Maska)
  .mount('#app');
