<template>
  <div class="c-function-icons" :class="{ 'c-function-icons--space-between': isMusicExpand }" v-if="data.list.length">
    <ul class="icon-list">

      <li class="icon-list__item" v-for="item of data.list" :key="item.icon" @click="handleActiveIcon(item.icon)">

      <tooltip :label="item.icon">
        <div
          class="icon-list__item__icon"
          :class="[`icon-list__item__icon--${item.icon}`, activeIcon(item.icon)]"
        ></div>
        </tooltip>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'FunctionIcons',
  components: {
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isMusicExpand: {
      type: Boolean,
      default: false,
    },
    activeFunctionName: {
      type: String,
      default: '',
    },
  },
  emits: ['update:activeFunctionName'],
  setup(props, { emit }) {
    const handleActiveIcon = (iconName) => {
      emit('update:activeFunctionName', iconName);
    };

    const activeIcon = (iconName) => {
      if (props.isMusicExpand) {
        if (iconName === props.activeFunctionName) {
          return `icon-list__item__icon--active--${props.activeFunctionName}`;
        }
      }

      // return `icon-list__item__icon--active--${iconName}`;

      return '';
    };

    return {
      handleActiveIcon,
      activeIcon,
    };
  },
});
</script>
<style lang="scss" scoped>
.icon-list {
  @include inline-flex();
  margin-right: 12px;

  &__item {
    width: 36px;
    height: 40px;
    @include flex(center);

    &:hover {
      .icon-list__item__icon {
        background-size: cover;

        &--playlists {
          background-image: url('~@/assets/myMusic/functionIcons/tab_playlists_active.svg');
        }

        &--download-history {
          background-image: url('~@/assets/myMusic/functionIcons/tab_download_active.svg');
        }

        &--my-note {
          background-image: url('~@/assets/myMusic/functionIcons/tab_note_active.svg');
        }

        &--play-history {
          background-image: url('~@/assets/myMusic/functionIcons/tab_play-log_active.svg');
        }

        &--search-history {
          background-image: url('~@/assets/myMusic/functionIcons/tab_search-log_active.svg');
        }

        &--preview-tool {
          background-image: url('~@/assets/myMusic/functionIcons/tab_video_active.svg');
        }

        &--move {
          background-image: url('~@/assets/myMusic/functionIcons/icon_move_active.svg');
        }

        &--copy {
        background-image: url('~@/assets/myMusic/functionIcons/icon_copy_active.svg');
      }

      &--delete {
        background-image: url('~@/assets/myMusic/functionIcons/icon_delete_active.svg');
      }

      &--invite {
        background-image: url('~@/assets/myMusic/functionIcons/icon_invite_active.svg');
      }

      &--download {
        background-image: url('~@/assets/myMusic/functionIcons/icon_download_active.svg');
      }

      }
    }

    & + & {
      margin-left: 12px;
    }

    &__icon {
      width: 36px;
      height: 36px;
      cursor: pointer;
      background-size: cover;

      &--move {
        background-image: url('~@/assets/myMusic/functionIcons/icon_move.svg');
      }

      &--copy {
        background-image: url('~@/assets/myMusic/functionIcons/icon_copy.svg');
      }

      &--delete {
        background-image: url('~@/assets/myMusic/functionIcons/icon_delete.svg');
      }

      &--invite {
        background-image: url('~@/assets/myMusic/functionIcons/icon_invite.svg');
      }

      &--download {
        background-image: url('~@/assets/myMusic/functionIcons/icon_download.svg');
      }

      &--tab-download {
        background-image: url('~@/assets/myMusic/functionIcons/tab_download.svg');
      }

      &--download-history {
        background-image: url('~@/assets/myMusic/functionIcons/tab_download.svg');
      }

      &--my-note {
        background-image: url('~@/assets/myMusic/functionIcons/tab_note.svg');
      }

      &--play-history {
        background-image: url('~@/assets/myMusic/functionIcons/tab_play-log.svg');
      }

      &--playlists {
        background-image: url('~@/assets/myMusic/functionIcons/tab_playlists.svg');
      }

      &--search-history {
        background-image: url('~@/assets/myMusic/functionIcons/tab_search-log.svg');
      }

      &--preview-tool {
        background-image: url('~@/assets/myMusic/functionIcons/tab_video.svg');
      }
    }

    &__icon {
      &--active {

        &--playlists {
          background-image: url('~@/assets/myMusic/functionIcons/tab_playlists_active.svg');
        }

        &--tab-download {
          background-image: url('~@/assets/myMusic/functionIcons/tab_download_active.svg');
        }

        &--my-note {
          background-image: url('~@/assets/myMusic/functionIcons/tab_note_active.svg');
        }

        &--play-history {
          background-image: url('~@/assets/myMusic/functionIcons/tab_play-log_active.svg');
        }

        &--search-history {
          background-image: url('~@/assets/myMusic/functionIcons/tab_search-log_active.svg');
        }

        &--preview-tool {
          background-image: url('~@/assets/myMusic/functionIcons/tab_video_active.svg');
        }
      }
    }
  }
}

@media screen and (min-width: $sm-pc) {
  .c-function-icons {
    &--space-between {
      .icon-list {
        width: 100%;
        justify-content: space-between;
        margin-right: 0px;

        &__item {
          width: 45px;
          height: 45px;

          &__icon {
            width: 45px;
            height: 45px;
          }
        }
      }
    }
  }
}
</style>
