export default [
  {
    path: '/licensing',
    component: () => import(/* webpackChunkName: "LayoutWithHeader" */ '@/layout/LayoutWithHeader.vue'),
    meta: {
      title: 'licensing',
      key: 'licensing',
    },
    children: [
      {
        path: '',
        name: 'licensing',
        component: () => import(/* webpackChunkName: "licensing" */ '@/views/Front/Licensing.vue'),
        meta: {
          title: '授權說明',
          layout: {

          },
        },
      },
      {
        path: 'terms',
        name: 'licensing-terms',
        component: () => import(/* webpackChunkName: "LicensingTerms" */ '@/views/Front/LicensingTerms.vue'),
        meta: {
          title: '細則及條款',
          layout: {

          },
        },
      },
      {
        path: 'privacy',
        name: 'licensing-privacy',
        component: () => import(/* webpackChunkName: "LicensingPrivacy" */ '@/views/Front/LicensingPrivacy.vue'),
        meta: {
          title: '隱私政策',
          layout: {

          },
        },
      },
      {
        path: 'cookies',
        name: 'licensing-cookies',
        component: () => import(/* webpackChunkName: "LicensingCookies" */ '@/views/Front/LicensingCookies.vue'),
        meta: {
          title: 'COOKIES 政策',
          layout: {

          },
        },
      },
    ],
  },
];
