<template>
  <div class="c-next function-icon">
    <img src="@/assets/icon/playbar_next.svg" @click="handleNext" />
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'Next',
  setup() {
    const musicPlayerStore = useMusicPlayerStore();

    const handleNext = () => {
      musicPlayerStore.handleNext();
    };

    return {
      handleNext,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
