<template>
  <div class="c-circle-style">
    <div class="music-wave">
      <div class="music-wave__bar music-wave__bar__wave1"></div>
      <div class="music-wave__bar music-wave__bar__wave2"></div>
      <div class="music-wave__bar music-wave__bar__wave3"></div>
      <div class="music-wave__bar music-wave__bar__wave4"></div>
    </div>

    <!-- circle -->
    <div class="circle">
      <svg class="circle__svg" height="94" width="94">
        <circle class="circle__svg__static" cx="47" cy="47" r="42" />
      </svg>

      <svg class="circle__svg" height="94" width="94">
        <circle
          class="circle__svg__dynamic"
          :style="{ 'stroke-dashoffset': songCircleOffset }"
          cx="47"
          cy="47"
          r="42"
          transform="rotate(-90, 47, 47)"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'MuiscCircle',
  setup() {
    const songCircleOffset = ref(314);

    let start = null;
    const songCircleAnimate = (timestamp) => {
      if (!start) start = timestamp;
      const progress = (timestamp - start) / 1000;
      const songLength = 100; // 歌的秒數
      const persentage = progress / songLength;
      const offset = persentage * 314;

      songCircleOffset.value = 314 - offset;

      if (progress < songLength) {
        window.requestAnimationFrame(songCircleAnimate);
      }
    };

    window.requestAnimationFrame(songCircleAnimate);

    return {
      songCircleOffset,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-circle-style {
  display: none;
}

@media screen and (min-width: $tablet) {
  .c-circle-style {
    width: 100%;
    height: 100%;
    display: inline-block;
    @include bg-gradient();
    @include circle(100px);
    cursor: pointer;
  }

  .music-wave {
    @include position(center);
    @include flex(center);
    width: 40px;
    height: 40px;

    &__bar {
      width: 6px;
      height: 40px;
      background-color: $c-white;
      transform-origin: bottom;

      &__wave1 {
        animation: wave1 0.45s infinite linear;
      }

      &__wave2 {
        animation: wave2 0.7s infinite linear;
      }

      &__wave3 {
        animation: wave3 0.9s infinite linear;
      }

      &__wave4 {
        animation: wave4 0.58s infinite linear;
      }

      & + & {
        margin-left: 5px;
      }
    }
  }
}

@keyframes wave1 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave2 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave3 {
  0% {
    transform: scaleY(1.1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1.1);
  }
}

@keyframes wave4 {
  0% {
    transform: scaleY(1.05);
  }

  50% {
    transform: scaleY(0.6);
  }

  100% {
    transform: scaleY(1.05);
  }
}

.circle {
  width: 100%;
  height: 100%;
  position: relative;

  &__svg {
    @include position(center);

    &__static {
      stroke: $c-assist3;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
    }

    &__dynamic {
      stroke: $c-white;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      stroke-dasharray: 314;
    }
  }
}
</style>
