<template>
  <div
    class="c-mobile-function"
    :class="{
      'c-mobile-function--active': showMobileFunction,
    }"
    @click.stop="close"
  >
    <div
      class="c-mobile-album-function"
      :class="{
        'c-mobile-album-function--active': showMobileFunction,
      }"
    >
      <div class="c-mobile-album-function__content">
        <div class="header">
          <div class="header__album-info">

            <cover-name-code-normal />
          </div>
          <div class="header__close">
            <img class="header__close__img" src="@/assets/mobile/icon_menu_close.svg" @click="close" />
          </div>
        </div>

        <ul class="function-list">
          <!-- 新增至我的最愛-->
          <li class="function-list__item" v-if="functionList.includes('add-to-favorite')">
            <add-to-favorite label />
          </li>
          <!-- 從我的最愛移除 -->
          <li class="function-list__item" v-if="functionList.includes('remove-from-favorite')">
            <remove-from-favorite label />
          </li>
          <!-- 新增至歌單 -->
          <li class="function-list__item" v-if="functionList.includes('add-to-playlists')">
            <add-to-playlists label />
          </li>
          <!-- 分享 -->
          <li class="function-list__item" v-if="functionList.includes('share')">
            <share label />
          </li>
          <!-- 編輯歌單 -->
          <li class="function-list__item" v-if="functionList.includes('edit-playlists')">
            <edit-playlists label />
          </li>
          <!-- 從我的歌單中移除 -->
          <li class="function-list__item" v-if="functionList.includes('remove-playlists')">
            <remove-playlists label />
          </li>
          <!-- 新增/查看筆記 -->
          <li class="function-list__item" v-if="functionList.includes('note')">
            <note label />
          </li>
          <!-- 專輯資訊 -->
          <li class="function-list__item" v-if="functionList.includes('album-info')">
            <album-info label />
          </li>
          <!-- 搬移至歌單 -->
          <li class="function-list__item" v-if="functionList.includes('move-to-playlists')">
            <move-to-playlists label />
          </li>
          <!-- 前往專輯頁面 -->
          <li class="function-list__item" v-if="functionList.includes('to-album-page')">
            <to-album-page label />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';
import AddToFavorite from '@/components/Global/MusicPlayer/Functions/AddToFavorite.vue';
import AddToPlaylists from '@/components/Global/MusicPlayer/Functions/AddToPlaylists.vue';
import Note from '@/components/Global/MusicPlayer/Functions/Note.vue';
import Share from '@/components/Global/MusicPlayer/Functions/Share.vue';
import ToAlbumPage from '@/components/Global/MusicPlayer/Functions/ToAlbumPage.vue';
import AlbumInfo from '@/components/Global/MusicPlayer/Functions/AlbumInfo.vue';
import RemovePlaylists from '@/components/Global/MusicPlayer/Functions/RemovePlaylists.vue';
import EditPlaylists from '@/components/Global/MusicPlayer/Functions/EditPlaylists.vue';
import RemoveFromFavorite from '@/components/Global/MusicPlayer/Functions/RemoveFromFavorite.vue';
import MoveToPlaylists from '@/components/Global/MusicPlayer/Functions/MoveToPlaylists.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'MobileFunction',
  components: {
    CoverNameCodeNormal,
    AddToFavorite,
    AddToPlaylists,
    Note,
    Share,
    ToAlbumPage,
    AlbumInfo,
    EditPlaylists,
    RemovePlaylists,
    RemoveFromFavorite,
    MoveToPlaylists,
  },
  props: {
    showFunction: {
      type: Boolean,
      default: false,
    },
    functionList: {
      type: Array,
      default: () => ['add-to-favorite', 'add-to-playlists', 'note', 'share', 'to-album-page'],
    },
  },
  emits: ['update:showFunction'],
  // props, { emit }
  setup() {
    const siteStore = useSiteStore();

    const close = () => {
      console.log('false...');
      // emit('update:showFunction', false);

      siteStore.toggleShowMobileFunction(false);
    };

    return {
      close,
      showMobileFunction: computed(() => siteStore.showMobileFunction),
    };
  },
});
</script>
<style lang="scss" scoped>
.c-mobile-function {
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 0.2);
  position: fixed;
  top: 100%;
  left: 0;
  z-index: $zi-mobile-function;
  transition: 0.3s;
  animation: fadeOut 0.4s forwards;

  &--active {
    display: inline-block;
    animation: fadeIn 0.3s forwards;
  }
}

.c-mobile-album-function {
  width: 100%;
  height: auto;
  background-color: rgba($c-white, 0.8);
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  transition: 0.3s;
  @include position(tl, 100%, 0);
  animation: contentSlideOut 0.3s  forwards;

  &--active {
    animation: contentSlideIn 0.3s forwards;
    background-color: rgba($c-white, 1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    top: 0%;
  }
  to {
    opacity: 1;
    top: 0%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    top: 0%;
  }

  99% {
    top: 0%;
  }

  100% {
    opacity: 0;
    top: 100%;
  }
}

@keyframes contentSlideIn {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes contentSlideOut {
  from {
    transform: translateY(-100%);
    }
  to {
    transform: translateY(0%);
  }
}

.header {
  @include flex(space-between);
  border-bottom: 1px solid $c-assist7;
  @include padding(13px 20px);

  &__album-info {
  }

  &__close {
    width: 18px;

    &__img {
      width: 100%;
      cursor: pointer;
    }
  }
}

.function-list {
  @include padding(20px);

  &__item {
    & + & {
      margin-top: 20px;
    }
  }
}
</style>
