<template>
  <div class="c-activeity-share-record">
    <div class="create-field">
      <button class="add-btn" @click="openAddNote">
          <img class="add-btn__icon" src="@/assets/searchResults/icon_popup_playlist_add_2.svg" />
          <span class="add-btn__text">Add</span>
        </button>

      <div class="create-field__box">
        <input class="create-field__box__input" placeholder="搜尋筆記" />

        <img class="create-field__box__search-icon" src="@/assets/icon/header_search_grey.svg" />

      </div>
    </div>
    <div class="activity-wrap">
      <ul class="activity-list">
        <li class="activity-list__item" v-for="item of activityData" :key="item.period">
          <div class="activity-list__item__box">
            <p class="activity-list__item__box__title">{{ item.period }}</p>
            <ul class="activity-detail-list" v-for="detail of item.record" :key="detail.time">
              <li class="activity-detail-list__item">
                <p class="activity-detail-list__item__title">{{ detail.title }}</p>
                <p class="activity-detail-list__item__time">{{ detail.time }}</p>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <n-modal v-model:show="modelStatus" preset="dialog" :show-icon="false" :style="{'max-width': '800px'}">
      <model-base-layout title="新增筆記 - Guiding You">
        <add-note />
      </model-base-layout>
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NModal } from 'naive-ui';
import ModelBaseLayout from '@/components/Global/Popup/ModelBaseLayout.vue';
import AddNote from '@/components/Global/Popup/AddNote.vue';

export default defineComponent({
  name: 'Note',
  components: {
    ModelBaseLayout,
    NModal,
    AddNote,
  },
  props: {
    activityTab: {
      type: String,
      default: '',
    },
  },
  setup() {
    const modelStatus = ref(false);

    const activityData = [
      {
        period: '本週',
        record: [
          {
            title: '播放 Amazing Things Will Happen',
            time: '14 Sep 2021 11:24 am',
          },
        ],
      },

      {
        period: '上個月',
        record: [
          {
            title: '播放 Amazing Things Will Happen 11',
            time: '15 Sep 2021 11:24 am',
          },
          {
            title: '播放 Amazing Things Will Happen 122',
            time: '13 Sep 2021 11:24 am',
          },
        ],
      },
      {
        period: '過去六個月',
        record: [
          {
            title: '播放 Amazing Things Will Happen 12321',
            time: '17 Sep 2021 11:24 am',
          },
          {
            title: '播放 Amazing Things Will Happen 45',
            time: '19 Sep 2021 11:24 am',
          },
        ],
      },
    ];

    const shareData = [
      {
        title: 'alley.zhan@howdesign.com.tw',
        time: '17 Sep 2021 11:24 am',
      },
      {
        title: 'ann@howdesign.com.tw',
        time: '18 Sep 2021 11:24 am',
      },
      {
        title: 'kyven@howdesign.com.tw',
        time: '19 Sep 2021 11:24 am',
      },
      {
        title: 'lynn@howdesign.com.tw',
        time: '20 Sep 2021 11:24 am',
      },
      {
        title: 'yvoone@howdesign.com.tw',
        time: '21 Sep 2021 11:24 am',
      },
    ];

    const openAddNote = () => {
      modelStatus.value = true;
    };

    return {
      activityData,
      shareData,
      modelStatus,
      openAddNote,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-activeity-share-record {
  @include padding(0 24px);
}

.create-field {
  margin-top: 30px;

  &__box {
    margin-top: 25px;
    @include flex();
    @include padding(0px 24px);
    height: 40px;
    border-radius: 20px;
    border: 1px solid $c-assist3;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;

      &::placeholder {
        color: $c-assist3;

      }
    }

    &__search-icon {
      width: 18px;

    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;
  background-color: transparent;

  &__icon {
    width: 14px;
  }

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}

.activity-wrap {
  height: 400px;
  overflow-y: auto;
  margin-top: 17px;
}

.activity-list {
  &__item {
    & + & {
      margin-top: 24px;
    }

    &__box {
      &__title {
        @include font-style($c-assist4, 14, normal, 0.7px, 20px);
      }
    }
  }
}

.activity-detail-list {
  margin-top: 8px;

  &__item {
    @include padding(22px 20px);
    background-color: $c-assist1;

    & + & {
      margin-top: 12px;
    }

    &__title {
      @include font-style($c-black, 16, normal, 0.8px, 22px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 18px);

      margin-top: 8px;
    }
  }
}

.share-wrap {
  margin-top: 17px;
}

.share-input {
  @include flex();

  &__to {
    @include font-style($c-black, 16, normal, 0.8px, 22px);
  }

  &__box {
    flex: 1;
    @include flex();
    @include padding(14px 25px);
    margin-left: 17px;
    border: 1px solid $c-assist3;
    height: 40px;
    border-radius: 20px;

    &__input {
      flex: 1;
      border: 0 transparent;
      outline: 0 transparent;
      background-color: transparent;
    }
  }
}

.add-btn {
  flex: none;
  width: auto;
  @include flex();
  border: 0 transparent;
  outline: 0 transparent;
  cursor: pointer;
  background-color: transparent;

  &__text {
    margin-left: 10px;
    @include font-style($c-main, 14, normal);
  }
}

.share-list-wrap {
  margin-top: 24px;
}

.total-share-count {
  @include font-style($c-assist4, 14, normal, 0.7px, 20px);
}

.share-list {
  margin-top: 8px;

  height: 400px;
  overflow-y: auto;

  &__item {
    height: 90px;
    @include flex();
    @include padding(0 20px);
    background-color: $c-assist1;

    & + & {
      margin-top: 12px;
    }

    &__box {
      width: 100%;
      @include flex(space-between);

      &__left {
        @include flex();
      }

      &__right {
        @include flex();
      }
    }
  }
}

.left {
  @include flex();

  &__img {
    @include circle(45px);
    overflow: hidden;
  }

  &__info {
    margin-left: 20px;

    &__title {
      @include font-style($c-black, 16, normal, 0.8px, 22px);
    }

    &__time {
      @include font-style($c-assist4, 12, normal, 0.6px, 28px);
    }
  }
}

.right {
  &__resend {
    height: 26px;
    @include flex(center);
    @include font-style($c-white, 14, normal, 0.7px, 20px);
    background-color: $c-assist15;
    border-radius: 13px;
    @include padding(0 10px);
  }

  &__delete {
    margin-left: 20px;
  }
}

</style>
