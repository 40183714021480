<template>
  <img
    class="player-btns__item player-btns__item--pause"
    src="@/assets/icon/playbar_play.svg"
    v-if="musicControll.playStatus === 'play' && color === 'white'"
    @click="handlePlayStatus('pause')"
  />
  <img
    class="player-btns__item player-btns__item--pause"
    src="@/assets/musicPlayer/playbar_play.svg"
    v-if="musicControll.playStatus === 'play' && color !== 'white'"
    @click="handlePlayStatus('pause')"
  />
  <img
    class="player-btns__item player-btns__item--play"
    src="@/assets/icon/playbar_pause.svg"
    v-if="musicControll.playStatus === 'pause'  && color === 'white'"
    @click="handlePlayStatus('play')"
  />
  <!-- FIXME: 換成灰色的暫停符號 -->
  <img
    class="player-btns__item player-btns__item--pause"
    src="@/assets/icon/playbar_pause_grey.svg"
    v-if="musicControll.playStatus === 'pause' && color !== 'white'"
    @click="handlePlayStatus('play')"
  />
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'PlayPause',
  props: {
    styleType: {
      type: String,
      default: 'circle',
    },
    color: {
      type: String,
      defualt: 'white',
    },
    type: {
      type: String,
      defualt: 'playlist', // 'playlists', 'albums', 'tracks'
    },
  },
  setup() {
    const musicPlayerStore = useMusicPlayerStore();

    const handlePlayStatus = (status) => {
      musicPlayerStore.handlePlayStatus(status);
    };

    return {
      musicControll: computed(() => musicPlayerStore.controll),
      handlePlayStatus,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';

.player-btns {
  &__item {
    cursor: pointer;
  }
}
</style>
