<template>
  <div class="c-queue">
    <ul class="row" v-for="d of mockData" :key="d.id">
      <li class="row__item">
        <ul class="queue-info-list">
          <li class="queue-info-list__item queue-info-list__item--version-info">
            <div class="queue-info">
              <cover-name-code-normal :data="d" />
            </div>
          </li>
          <li class="queue-info-list__item queue-info-list__item--length">
            <div class="queue-info">
              <time-length :data="d" />
            </div>
          </li>
          <li class="queue-info-list__item queue-info-list__item--more">
            <div class="queue-info">
              <more :data="d" />
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import CoverNameCodeNormal from '@/components/Global/MusicPlayer/Fragment/CoverNameCodeNormal.vue';
import TimeLength from '@/components/Global/MusicPlayer/Fragment/TimeLength.vue';
import More from '@/components/Global/MusicPlayer/Fragment/More.vue';

export default defineComponent({
  name: 'Queue',
  components: {
    CoverNameCodeNormal,
    TimeLength,
    More,
  },

  setup() {
    const mockData = [
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-1',
      },
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-2',
      },
      {
        main: 'Full length',
        sub: 'Vocal Lead',
        length: '03:08',
        id: 'EN026-3',
      },
    ];

    return {
      mockData,
    };
  },
});
</script>
<style lang="scss" scoped>
.row {
  &__item {

  }

  & + & {
      margin-top: 20px;
    }
}

.queue-info-list {
  @include flex();

  &__item {
    width: 100%;

    &--version-info {
      flex: 1;
    }

    &--length {
      @include flex(center);
      flex: none;
      width: 45px;
    }

    &--more {
      @include flex(center);
      flex: none;
      width: 45px;
    }
  }
}
</style>
