<template>
  <li
    class="mi-select-option"
    :class="[
      {
        'mi-select-option--active': String(currOption) === String(value),
        'mi-select-option--label': $attrs.type === 'label',
        'mi-select-option--disabled': disabled,
      },
    ]"
    :data-option-value="value"
    :data-disabled-status="disabled"
    :data-label="$attrs.type === 'label'"
    :selected="String(currOption) === String(value)"
    @click.stop="handleOption(value, $attrs.type)"
  >
    <span class="mi-select-option__text">{{ label }}</span>
    <img v-show="String(currOption) === String(value)" class="mi-select-option__icon" src="@/assets/icon/icon_select_red.svg">

  </li>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MiSelectOption',

  props: {
    value: {
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    render: {
      type: Function,
      // defualt: null,
    },
    currOption: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handleOption'],
  setup(props, { emit }) {
    const handleOption = (value, type) => {
      // 點到標題時，沒有值
      if (type === 'label') return;
      emit('handleOption', value);
    };

    return {
      handleOption,

    };
  },
});
</script>

<style lang="scss" scoped>
.mi-select-option {
  @include padding(10px);
  @include font-style($c-assist, 14px);
  @include flex();
  width: 100%;
  color: $c-assist;
  cursor: pointer;
  height: 35px;

  &:not([data-label='true']),
  &[data-disabled-status='true'] {
    &:hover {
      color: $c-black;
      // background-color: rgba($c-main, 0.1);
    }
  }

  &--active {
    color: $c-black;
    // background-color: rgba($c-main, 0.1);
  }

  &--label {
    @include font-style($c-assist4, 14, normal, 0.7px);
    position: relative;
    background-color: transparent;
    cursor: default;

    &::after {
      @include position(tl, calc(100% - 1px), 50%);
      transform: translateX(-50%);
      content: '';
      display: inline-block;
      width: calc(100% - 20px);
      height: 1px;
      background-color: #ccc;
    }
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &__text {
    flex: 1;

  }

  &__img {
    flex: none;
    width: auto;
  }
}
</style>
