<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <div class="app-root" @scroll="handleScroll" ref="appRoot">
      <router-view />
      <music-player />

      <Transition name="slide-fade">
        <!-- v-if 為了觸發 內部 onMounted 之後的自動 focus -->
        <menu-search-pop v-if="showSearchPop" />
      </Transition>
      <Transition name="slide-fade">
        <mobile-menu-content v-show="showMobileMenuContent" />
      </Transition>
      <mobile-music-player v-show="showMobileMusicPlayer" />
      <mobile-function v-show="showMobileFunction" />
    </div>
  </n-config-provider>
</template>

<script>
import {
  defineComponent, onMounted, ref, computed,
} from 'vue';

import { NConfigProvider } from 'naive-ui';
import { debounce } from 'lodash-es';
import MusicPlayer from '@/components/Global/MusicPlayer/index.vue';
import MenuSearchPop from '@/layout/MenuSearchPop.vue';
import MobileMenuContent from '@/layout/MobileMenuContent.vue';
import MobileMusicPlayer from '@/components/Global/MusicPlayer/MobileMusicPlayer.vue';
import { useSiteStore } from '@/store/site';
import MobileFunction from '@/components/Global/MusicPlayer/MobileFunction.vue';

export default defineComponent({
  name: 'App',
  components: {
    MusicPlayer,
    NConfigProvider,
    MenuSearchPop,
    MobileMenuContent,
    MobileMusicPlayer,
    MobileFunction,
  },
  setup() {
    const siteStore = useSiteStore();
    const appRoot = ref(null);
    const themeOverrides = {
      common: {
        primaryColor: '#BA2424',
        primaryColorHover: '#BA2424',
      },
    };

    const reCalcWindowWidthDebounce = debounce(() => {
      siteStore.setDeviceType();
    }, 100);

    // 手機版會將網頁狀態列計入高度，會導致一些滑動bug，所以手動設置一個
    function set100VhVar() {
      const vh = window.innerHeight / 100;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    onMounted(() => {
      siteStore.setAppRoot(appRoot.value);
      siteStore.setDeviceType();
      window.addEventListener('resize', reCalcWindowWidthDebounce);

      set100VhVar();
    });

    return {
      appRoot,
      themeOverrides,
      showSearchPop: computed(() => siteStore.showSearchPop),
      showMobileMenuContent: computed(() => siteStore.showMobileMenuContent),
      showMobileMusicPlayer: computed(() => siteStore.showMobileMusicPlayer),
      showMobileFunction: computed(() => siteStore.showMobileFunction),
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/share/animate.scss';
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  color: $c-white;
  z-index: 1000;
}
.ly-full-header-layout {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
}

.ly-fh {
  &__header {
    width: 100%;
    // height: 52px;
    // position: fixed;
    // z-index: 100;
    // top: 0;
    // left: 0;
  }

  &__ab-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    // @include padding(60px 0 0);

    // &__aside {
    //   flex: none;
    //   width: 200px;
    //   height: 100%;
    //   overflow-y: auto;
    // }

    &__body {
      flex: 1;
      width: 100%;
      height: 100%;
      // overflow-y: auto;
    }
  }
}
</style>
