<template>
  <div class="c-label-item" :class="{'c-label-item--is-search': isSearch, 'c-label-item--is-search-pop': isSearchPop}">
    <p class="c-label-item__text" @click="addLabel(label)">
      {{label}}
    </p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useSearchStore } from '@/store/search';

export default defineComponent({
  name: 'LabelItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isSearchPop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const searchStore = useSearchStore();

    const addLabel = (label) => {
      console.log('addLabel');
      searchStore.setIncludeLabels(label);
    };

    return {
      addLabel,
    };
  },
});
</script>
<style lang="scss" scoped>

.c-label-item {
  height: 25px;
  @include padding(0 5px);
  @include inline-flex(center);
  border: 1px solid $c-main;
  margin-right: 10px;
  margin-bottom: 10px;

  &--is-search {
    height: 34px;
    @include padding(0 10px);

    .c-label-item__text {
      @include font-style($c-assist6, 14, 600, normal, 18px);
    }

  }

  &--is-search-pop {
    height: 34px;
    @include padding(0 10px);
    background-color: $c-main;

    .c-label-item__text {
      @include font-style($c-white, 14, 600, normal, 18px);
    }

  }

  &__text {
    @include font-style($c-main, 12, 600, normal, 18px);
  }
}

</style>
