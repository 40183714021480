<template>
  <div class="c-to-album-page function-icon">
    <div class="function-icon" @click="handleNext">
      <img class="function-icon__img" src="@/assets/mobile/icon_menu_info.svg" />
      <span class="function-icon__label" v-if="label"> 專輯資訊 </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'Share',
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const musicPlayerStore = useMusicPlayerStore();

    const handleNext = () => {
      musicPlayerStore.handleNext();
    };

    return {
      handleNext,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
