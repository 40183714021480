<template>
  <div class="c-version-info">
    <p class="version-title">
      <span class="version-title__main">{{ data.main }}</span>
      <span class="version-title__sub">({{ data.sub }})</span>
    </p>
    <p class="version-id">{{ data.id }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VersionInfo',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
    idx: {
      type: Number,
    },
  },
});
</script>
<style lang="scss" scoped>
.c-version-info {
}

.version-title {
  &__main {
    @include font-style($c-assist6, 14, bold, 0.7px, 26px);
  }

  &__sub {
    margin-left: 4px;
    @include font-style($c-assist4, 14, normal, 0.7px, 26px);
  }
}

.version-id {
  @include font-style($c-assist4, 14, normal, 0.7px, 26px);
}
</style>
