<template>
  <div class="c-tooltip">
    <slot></slot>
    <p class="c-tooltip__desc">{{ label }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Tooltip',
  props: {
    label: {
      type: String,
      default: 'tooltip',
    },
  },
});
</script>
<style lang="scss" scoped>
.c-tooltip {
  position: relative;

  &__desc {
    background-color: rgba($c-black, 0.8);
    border-radius: 4px;
    @include padding(4px 6px);
    @include position(tl, -100%, 50%);
    transform: translateX(-50%);
    display: none;
    white-space: nowrap;
    color: $c-white;
  }
}

@media screen and (min-width: $sm-pc) {
  .c-tooltip {
    position: relative;

    &:hover {
      .c-tooltip__desc {
        display: inline-block;
      }
    }
  }
}

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//     display: inline-block;
//   }

//   100% {
//     opacity: 1;
//   }
// }
</style>
