<template>
  <div class="c-my-music-list">
    <div class="c-my-music-list__header">
      <list-header :columns="config.columns" :handleCheckStatus="handleCheckStatus" />
    </div>
    <div class="c-my-music-list__content">
      <list-content :columns="config.columns" :contentData="contentData" />
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, provide } from 'vue';
import ListHeader from '@/components/Global/MusicPlayer/VersionQueueList/ListHeader.vue';
import ListContent from '@/components/Global/MusicPlayer/VersionQueueList/ListContent.vue';

export default defineComponent({
  name: 'VersionQueueList',
  components: {
    ListHeader,
    ListContent,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    contentData: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const isCheckAll = ref(false);

    const handleCheckStatus = (status) => {
      console.log('status', status);

      isCheckAll.value = status;
    };

    provide('isCheckAll', isCheckAll);

    return {
      handleCheckStatus,

    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list {
  width: 100%;
  height: 100%;
  @include flex(flex-start, flex-start, column);

  &__header {
    width: 100%;
    flex: none;
    height: auto;
  }

  &__content {
    width: 100%;
    height: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>
