<template>
  <div class="c-share function-icon">
    <tooltip label="Download">
      <div class="function-icon" @click="download">
        <div class="function-icon__img function-icon__img--download"></div>
        <span class="function-icon__label" v-if="label"> 下載 </span>
      </div>
    </tooltip>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import Tooltip from '@/components/Global/Tooltip.vue';
// import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'Download',
  components: {
    Tooltip,
  },
  props: {
    label: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // const musicPlayerStore = useMusicPlayerStore();

    const download = () => {
      console.log('download');
      // musicPlayerStore.handleNext();
    };

    return {
      download,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/functions.scss';
</style>
