<template>
  <div class="c-add-note">
    <p class="c-add-note__remaining">{{ 200 - noteValue.length }}/200</p>
    <div class="c-add-note__note">
      <n-input type="textarea" v-model:value="noteValue" :rows="15" placeholder="新增筆記 ..." />
    </div>

    <div class="submit">
      <mi-button> Submit </mi-button>
    </div>

    <div class="cancel">
      <button class="cancel__btn">Cancel</button>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NInput } from 'naive-ui';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'AddNote',
  components: {
    NInput,
    MiButton,
  },
  setup() {
    const noteValue = ref('');

    return {
      noteValue,
    };
  },
});
</script>
<style lang="scss" scoped>

.c-add-note {
  @include padding(0 24px);

  &__remaining {
    @include flex(flex-end);
    @include font-style($c-assist3, 12, 600, 0.6px, 18px);
  }

  &__note {
    margin-top: 6px;
  }
}

.submit {
  margin-top: 26px;
  @include flex(center);
}

.cancel {
  margin-top: 16px;
  @include flex(center);

  &__btn {
    cursor: pointer;
    @include font-style($c-assist3, 14px, 600, 0.7px, 18px);
    border: 0px transparent;
  outline: 0px transparent;
  background-color: transparent;
  }
}
</style>

<style lang="scss">

</style>
