<template>
  <div class="c-cover-name-code">
    <img class="c-cover-name-code__cover" src="@/assets/explore/playlists_img.jpg" />
    <p class="c-cover-name-code__info">
      <span class="c-cover-name-code__info__name">Bags of Fun fjdslkfjs</span>
      <span class="c-cover-name-code__info__code">EN027-1</span>
    </p>
  </div>
</template>
<script>
// 與 CoverNameCode 差別在於沒有圓角，和 margin 負值
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CoverNameCodeNormal',
});
</script>
<style lang="scss" scoped>
.c-cover-name-code {
  @include flex();

  &__cover {
    width: 36px;
    height: 36px;
  }

  &__info {
    margin-left: 16px;
    @include flex(flex-start, flex-start, column);

    &__name {
      text-align: left;
      @include font-style($c-assist6, 14, bold, 0.8px, 18px);
      @include word-ellipsis(1);
    }

    &__code {
      text-align: left;
      @include font-style($c-assist4, 14, 400, 0.8px, 18px);
      @include word-ellipsis(1);
    }
  }
}
</style>
