<template>
  <div class="ly-mobile-menu-content">
    <div class="ly-mobile-menu-content__content">
      <div class="member-info" v-if="isLogin">
        <avatar />
        <sign-out />
      </div>
      <div class="member-info member-info--not-login" v-else>
        <div class="member-info__sign-in">
          <img class="member-info__sign-in__user-icon" src="@/assets/icon/header_user_black.svg" />
          <sign-in />
        </div>
        <p class="member-info__no-account">
          Don’t have an account?

            <span class="member-info__no-account__underline" @click="toSignUp">Sign up</span>

        </p>
      </div>

      <divide-line v-if="!isLogin"/>
      <div class="mobile-menu">
        <mobile-menu />
      </div>
      <div class="declare">
        <ul class="declare-list">
          <li class="declare-list__item" v-for="d of declareList" :key="d.label">
            <p class="declare-list__item__text" @click="toPage(d.routeName)">{{ d.label }}</p>
          </li>
        </ul>
      </div>
      <div class="language">
        <img src="@/assets/icon/header_language_black.svg" />
        <language-list mobileMenuContent />
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';
import Avatar from '@/components/MyProfile/Avatar.vue';
import SignOut from '@/components/MyProfile/SignOut.vue';
import SignIn from '@/components/MyProfile/SignIn.vue';
import MobileMenu from '@/components/Layout/MobileMenu.vue';
import LanguageList from '@/components/Layout/Language/LanguageList.vue';
import DivideLine from '@/components/MyProfile/DivideLine.vue';

export default defineComponent({
  name: 'MobileMenuContent',
  components: {
    Avatar,
    SignOut,
    SignIn,
    MobileMenu,
    LanguageList,
    DivideLine,
  },
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();
    const deviceType = computed(() => siteStore.deviceType);

    watch(deviceType, () => {
      if (deviceType.value.includes('tablet')) {
        siteStore.toggleShowMobileMenuContent(false);
      }
    });

    const declareList = [
      {
        label: '條款及細則',
        routeName: 'licensing-terms',
      },
      {
        label: '隱私政策',
        routeName: 'licensing-privacy',
      },
      {
        label: 'COOKIES 政策',
        routeName: 'licensing-cookies',
      },
    ];

    const showMobileMenuContent = computed(() => siteStore.showMobileMenuContent);
    const toSignUp = () => {
      router.push({
        name: 'sign-up',
      });
      siteStore.toggleShowMobileMenuContent(!showMobileMenuContent.value);
    };

    const toPage = (name) => {
      router.push({ name });
      siteStore.toggleShowMobileMenuContent(false);
    };

    return {
      declareList,
      toPage,
      isLogin: computed(() => siteStore.isLogin),
      toSignUp,
    };
  },
});
</script>
<style lang="scss" scoped>
.ly-mobile-menu-content {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  width: 100%;

  z-index: $zi-mobile-menu-content;
  background-color: $c-white;

  &__content {
    height: 100%;
    overflow: auto;
  }
}

.member-info {
  @include flex();
  background-color: $c-white;
  @include padding(20px 20px 0);
  // border-bottom 1px solid #

  &--not-login {
    display: block;
  }

  &__sign-in {
    @include flex();

    &__user-icon {
      width: 40px;
      margin-right: 10px;
    }
  }

  &__no-account {
    @include font-style($c-assist3, 14, bold, 0.28px, 24px);

    &__underline {
      display: inline-block;
      color: $c-assist3;
      text-decoration: underline;
      margin-top: 20px;
      cursor: pointer;
    }
  }

}

.mobile-menu {
  margin-bottom: 30px;
}

.declare {
  @include padding(0 20px);
  margin-bottom: 100px;
}

.declare-list {
  @include flex();

  &__item {
    position: relative;

    & + & {
      padding-left: 21px;

      &::before {
        content: '';
        height: 14px;
        width: 1px;
        background-color: $c-assist16;
        @include position(tl, 50%, 10px);
        transform: translateY(-50%);
        display: inline-block;
      }
    }

    &__text {
      @include font-style($c-assist16, 14, 400, 0, 0);
      cursor: pointer;
    }
  }
}

.language {
  @include position(bl, 0px, 0);
  @include padding(0 20px);
  @include flex();
  height: 50px;
  background-color: $c-assist2;
  margin-top: 10px;
  width: 100%;

  &::before {
    content: '';
    width: 100%;
    height: 50px;
    background: linear-gradient(0deg, rgba($c-white, 1) 0%, transparent 100%);
    @include position(bl, 100%, 0);
  }
}

</style>
