<template>
  <div class="c-music-player" :class="[`c-music-player--${styleType}`]">
    <Transition name="slide-fade-bottom">
      <queue v-show="expandStatus.queue" v-model:queue="expandStatus.queue" />
    </Transition>
    <Transition name="slide-fade-bottom">
      <version v-show="expandStatus.version" v-model:version="expandStatus.version" />
    </Transition>
    <div class="c-music-player__content">
      <!-- mobile -->
      <div class="mobile-music-player" @click="openMobileMusicPlayer">
        <div class="mobile-music-player__content">
          <div class="mobile">
            <div class="mobile__album-cover">
              <img class="mobile__album-cover__img" :src="musicInfo.albumCoverUrl" />
            </div>
            <div class="mobile__main">
              <div class="mobile__main__music-info">
                <music-info style-type="mobile" />
              </div>
              <div class="mobile__main__music-controll" @click.stop>
                <music-controll style-type="mobile" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- above tablet 有三種形式 -->
      <circle-style @click="switchStyleType" v-show="styleType === 'circle'" />
      <div class="standard-style" :class="[`standard-style--${styleType}`]" v-show="styleType !== 'circle'">
        <div class="dashboard">
          <div class="dashboard__album-cover" v-show="styleType === 'full'">
            <img class="dashboard__album-cover__img" :src="musicInfo.albumCoverUrl" />
          </div>
          <div class="dashboard__main" :class="`dashboard__main--${styleType}`">
            <div class="dashboard__main__upper">
              <div class="dashboard__main__upper__music-info">
                <music-info :style-type="styleType" />
              </div>
              <div class="dashboard__main__upper__music-controll">
                <music-controll :style-type="styleType" />
              </div>
              <div class="dashboard__main__upper__music-function">
                <music-function :showPlay="false" />
              </div>
            </div>
            <div class="dashboard__main__bottom" :class="`dashboard__main__bottom--${styleType}`">
              <div class="dasboard__main__bottom__music-sound">
                <music-volume
                  :style-type="styleType"
                  v-model:version="expandStatus.version"
                  v-model:queue="expandStatus.queue"
                />
              </div>
            </div>
          </div>
          <div class="dashboard__switch-icon" :class="`dashboard__switch-icon--${styleType}`">
            <img class="dashboard__switch-icon__img" src="@/assets/icon/playbar_close.svg" @click="switchStyleType" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, ref, computed, reactive,
} from 'vue';
import CircleStyle from '@/components/Global/MusicPlayer/CircleStyle.vue';
import MusicControll from '@/components/Global/MusicPlayer/MusicControll.vue';
import MusicFunction from '@/components/Global/MusicPlayer/MusicFunction.vue';
import MusicInfo from '@/components/Global/MusicPlayer/MusicInfo.vue';
import MusicVolume from '@/components/Global/MusicPlayer/MusicVolume.vue';
import Version from '@/components/Global/MusicPlayer/Version.vue';
import Queue from '@/components/Global/MusicPlayer/Queue.vue';
import { useSiteStore } from '@/store/site';
import { useMusicPlayerStore } from '@/store/musicPlayer';

export default defineComponent({
  name: 'MuiscPlayer',
  components: {
    CircleStyle,
    MusicControll,
    MusicFunction,
    MusicInfo,
    MusicVolume,
    Version,
    Queue,
  },
  setup() {
    const siteStore = useSiteStore();
    const musicPlayerStore = useMusicPlayerStore();
    const styleType = ref('circle');
    const expandStatus = reactive({
      version: false,
      queue: false,
    });

    const switchStyleType = () => {
      const mappingStyleType = ['circle', 'full', 'simple'];

      const nextStyleTypeIndex = (mappingStyleType.indexOf(styleType.value) + 1) % 3;
      styleType.value = mappingStyleType[nextStyleTypeIndex];

      musicPlayerStore.setFooterBottomExtraPaddingType(styleType.value);

      if (styleType.value !== 'full') {
        expandStatus.version = false;
        expandStatus.queue = false;
      }
    };

    const openMobileMusicPlayer = () => {
      siteStore.toggleShowMobileMusicPlayer(true);
    };

    return {
      styleType,
      switchStyleType,
      musicInfo: computed(() => musicPlayerStore.info),
      expandStatus,
      openMobileMusicPlayer,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '~@/styles/share/animate.scss';
.c-music-player {
  // display: none;
}

.mobile-music-player {
  background-color: $c-black;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 55px;

  &__content {
    @include padding(0 30px 12px);
  }
}

.mobile {
  @include flex();

  &__album-cover {
    position: relative;
    top: -7px;

    &__img {
      width: 50px;
      height: 50px;
      vertical-align: bottom;
    }
  }

  &__main {
    width: 100%;
    @include flex();

    &__music-info {
      flex: 1;
      sflex: abs($number: 0);
    }

    &__music-controll {
      flex: none;
      width: auto;
    }
  }
}

@media screen and (min-width: $tablet) {
  .mobile-music-player {
    display: none;
  }
  .c-music-player {
    display: inline-block;
    position: fixed;
    z-index: $zi-player;

    &__type {
    }

    &__content {
      width: 100%;
      height: 100%;
      // position: relative;
      // z-index: 10;
    }
  }

  .music-wave {
    @include position(center);
    @include flex(center);
    width: 40px;
    height: 40px;

    &__bar {
      width: 6px;
      height: 40px;
      background-color: $c-white;
      transform-origin: bottom;

      &__wave1 {
        animation: wave1 0.45s infinite linear;
      }

      &__wave2 {
        animation: wave2 0.7s infinite linear;
      }

      &__wave3 {
        animation: wave3 0.9s infinite linear;
      }

      &__wave4 {
        animation: wave4 0.58s infinite linear;
      }

      & + & {
        margin-left: 5px;
      }
    }
  }

  .circle {
    width: 100%;
    height: 100%;
    position: relative;

    &__svg {
      @include position(center);
    }

    &__svg-static {
      stroke: $c-assist3;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      @include position(center);
    }

    &__svg-dynamic {
      stroke: $c-white;
      stroke-width: 1px;
      fill: transparent;
      fill-opacity: 0;
      @include position(center);
    }
  }

  .dashboard {
    @include flex();
    width: 100%;

    &__album-cover {
      width: 110px;
      &__img {
        width: 100%;
        vertical-align: bottom;
        margin-top: -80px;
      }
    }

    &__main {
      flex: 1;
      width: 100%;
      @include flex(flex-end, flex-end, column);

      &__upper {
        width: 100%;
        @include flex(flex-start);

        &__music-info {
          flex: none;
          width: auto;
          display: flex;
          align-self: flex-end;
        }

        &__music-controll {
          margin-left: 26px;
          flex: 1;
        }

        &__music-function {
          flex: none;
          width: auto;
          margin-left: 26px;
        }
      }

      &__bottom {
        margin-top: 5px;
        margin-left: 10px;

        &__music-sound {
        }
      }
    }

    &__switch-icon {
      flex: none;
      width: auto;
      margin-left: 18px;
      display: flex;
      align-self: flex-start;
      cursor: pointer;

      &__img {
        width: 16px;
      }
    }
  }

  // 圓形播放器
  .c-music-player--circle {
    @include circle(100px);
    bottom: 110px;
    right: 110px;
  }

  // 完整版的播放器
  .c-music-player--full {
    width: 100%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .standard-style {
      background-color: rgba($c-black, 0.92);
      position: relative;
      @include padding(20px 20px 18px 36px);
      width: 100%;
      height: auto;
    }
  }

  // 簡易版的播放器
  .c-music-player--simple {
    @include max-width(1700);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    .standard-style {
      background-color: $c-black;
      position: relative;
      @include padding(10px);
      height: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .dashboard {
      &__main {
        @include flex(flex-start, center, row);

        &__bottom {
          margin-top: 0px;
          margin-left: 93px;
        }
      }

      &__switch-icon {
        margin-left: 40px;
        align-self: center;
      }
    }
  }
}

@keyframes wave1 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.75);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave2 {
  0% {
    transform: scaleY(0.9);
  }

  50% {
    transform: scaleY(0.5);
  }

  100% {
    transform: scaleY(0.9);
  }
}

@keyframes wave3 {
  0% {
    transform: scaleY(1.1);
  }

  50% {
    transform: scaleY(0.4);
  }

  100% {
    transform: scaleY(1.1);
  }
}

@keyframes wave4 {
  0% {
    transform: scaleY(1.05);
  }

  50% {
    transform: scaleY(0.6);
  }

  100% {
    transform: scaleY(1.05);
  }
}
</style>
