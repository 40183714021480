<template>
  <div class="avatar">
      <div class="avatar__content">
        <div class="avatar-box">
          <img class="avatar-box__img" src="@/assets/profile/img_profile.png" />

          <div class="edit-avatar">
            <img class="edit-avatar__icon" src="@/assets/profile/icon_edit.svg" />
            <p class="edit-avatar__text">更換</p>
          </div>
        </div>

        <span class="member">
          <p class="member__name">Joyce Lee</p>
          <p class="member__level">正式會員</p>
        </span>
      </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Avatar',
});
</script>
<style lang="scss" scoped>

.avatar {
  flex: none;
  width: 50%;
  @include flex();

  &__content {
    @include flex();
  }
}

.avatar-box {
  @include circle(52px);
  overflow: hidden;
  position: relative;

  &__img {
    width: 52px;
    height: 52px;
  }
}

.edit-avatar {
  display: none;
}

.member {
  margin-left: 16px;

  &__name {
    @include font-style($c-black, 16, bold, 0px, 22px);
  }

  &__level {
    @include font-style($c-assist3, 14, normal, 0px, 22px);
  }
}

@media screen and (min-width: $tablet) {
   .avatar {
    flex: 1;
    width: 100%;
    @include flex(center, center);

    &__content {
      @include padding(36px 0 30px 0);
      @include flex(center, center, column);
    }
  }

  .avatar-box {
    @include circle(150px);
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    &__img {
      width: 150px;
      height: 150px;
    }
  }

  .edit-avatar {
    @include position(bl, 0px, 0);
    @include flex(center, center);
    background-color: rgba($c-black, 0.6);
    width: 100%;
    height: 30px;

    &__icon {
      width: 13px;
      cursor: pointer;
    }

    &__text {
      margin-left: 2px;
      @include font-style($c-white, 13, bold, 0, 24px);
    }
  }

  .member {
    &__name {
      @include font-style($c-black, 24, bold, 1.2px, 32px);
      margin-top: 18px;
      text-align: center;
    }

    &__level {
      @include font-style($c-assist3, 14, normal, 0.28px, 24px);
      margin-top: 12px;
      text-align: center;
    }
  }

}

</style>
