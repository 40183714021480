import TitleWithFunction from '@/components/Global/MusicPlayer/Fragment/TitleWithFunction.vue';
// import Avatar from '@/components/Global/MusicPlayer/Fragment/Avatar.vue';
// import Track from '@/components/Global/MusicPlayer/Fragment/Track.vue';
// import Remove from '@/components/Global/MusicPlayer/Fragment/Remove.vue';
// import ModifyDate from '@/components/Global/MusicPlayer/Fragment/ModifyDate.vue';

export const functionConfig = {
  addPlaylistsBtn: {
    visible: true,
  },
  downloadBtn: {
    visible: true,
  },
};

export const listConfig = {
  columns: [
    {
      type: 'label',
      label: 'TITLE',
      align: 'left',
      key: 'playlistsName',
      render: TitleWithFunction,
    },
    {
      type: 'label',
      label: 'LENGTH',
      width: '100px',
      align: 'left',
      key: 'length',

    },
    {
      type: 'label',
      label: 'ID',
      width: '100px',
      align: 'left',
      key: 'id',

    },

    {
      type: 'checkbox',
      label: 'C',
      width: '60px',
      align: 'center',
      key: 'checkbox',
    },

  ],

};

export const contentData = [
  {
    playlistsName: '我的最愛',
    owner: 'vicky',
    track: 10,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:17',
    id: 'MAT305-5',
    title: '30 Sec',
    titleExtra: 'Instrumental',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:17',
    id: 'MAT305-5',
    title: '15 Sec',
    titleExtra: 'Instrumental',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:17',
    id: 'MAT305-5',
    title: '15 Sec',
    titleExtra: 'Instrumental',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:17',
    id: 'MAT305-5',
    title: '15 Sec',
    titleExtra: 'Instrumental',
  },

  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:17',
    id: 'MAT305-5',
    title: '15 Sec',
    titleExtra: 'Instrumental',
  },
  {
    playlistsName: '哈哈',
    name: 'becky',
    track: 3,
    modifyDate: '27 Aug 2021',
    remove: 'x',
    length: '03:17',
    id: 'MAT305-5',
    title: '15 Sec',
    titleExtra: 'Instrumental',
  },
];
