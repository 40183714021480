<template>
  <div class="c-my-music-list-header">
    <div class="c-my-music-list-header__content">
      <ul class="header-list">
        <template v-for="col of columns" :key="col.label">
          <li
            class="header-list__item"
            :class="`header-list__item--${col.type}`"
            :style="{ flex: col.width ? 'none' : '1', width: col.width,  'text-align': col.align }"
          >
            <template v-if="col.type === 'label'">
              {{ col.label }}
            </template>
            <template v-if="col.type === 'checkbox'">
            <div class="center">
              <n-checkbox  :on-update:checked="handleCheckAll"/>

              <!-- v-model:checked="isCheck" -->
              </div>
            </template>
            <template v-if="col.type === 'action'">
              <!-- <img src="@/assets/myMusic/icon_more.svg" /> -->
            </template>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { NCheckbox } from 'naive-ui';

export default defineComponent({
  name: 'MyMusicListHeader',
  components: {
    NCheckbox,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    handleCheckStatus: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const isCheck = ref(true);

    const handleCheckAll = (checked) => {
      console.log('handleCheckAll', checked);
      props.handleCheckStatus(checked);
    };

    return {
      isCheck,
      handleCheckAll,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-list-header {
  // background-color: $c-white;
  // box-shadow: 0px 3px 10px rgba($c-black, 0.05);

  &__content {
    width: 100%;
    border-bottom: 1px solid $c-assist8;
    // @include max-width(1330);
    @include padding(0 0 20px 0);
  }
}
.header-list {
  width: 100%;
  @include flex();
  // @include padding(16px 0);

  &__item {
    @include font-style($c-assist4, 14, medium, 0.7px, 20px);
  }
}

.center {
  @include flex(center);
}
</style>
