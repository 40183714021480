<template>
  <ul>
    <li class="c-mobile-menu-item" v-for="m of menuList" :key="m.title" v-bind="m">
    <!-- 如果需要權限，判斷是否已登入 -->
      <div class="item-content" v-if="!m.auth  || (m.auth  && isLogin)">
        <div class="title" @click="toPage(m.routeInfo)">
          <p class="title__text">{{ m.title }}</p>
          <img v-if="m.arrow" class="title__icon" src="@/assets/icon/icon_arrow_right_black.svg" />
        </div>
        <div class="extra-content" v-if="m.render">
          <component :is="m.render" />
        </div>
        <div class="divide" v-if="m.divide"></div>
      </div>
    </li>
  </ul>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useSiteStore } from '@/store/site';
import MobileMenuExplore from '@/components/Layout/MobileMenuExplore.vue';

export default defineComponent({
  name: 'MobileMenuItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    render: {
      default: null,
    },
  },
  setup() {
    const router = useRouter();
    const siteStore = useSiteStore();

    const toPage = (routeInfo) => {
      if (routeInfo) {
        router.push(routeInfo);
        siteStore.toggleShowMobileMenuContent(false);
      }
    };

    const menuList = [
      {
        title: 'MY PROFILE 我的帳戶',
        arrow: true,
        routeInfo: {
          name: 'my-profile-personal-details',
        },
        auth: true,
      },
      {
        title: 'MY MUSIC 我的音樂',
        arrow: true,
        divide: true,
        routeInfo: {
          name: 'my-music-playlists',
        },
        auth: true,
      },
      {
        title: 'EXPLORE 探索',
        arrow: false,
        render: MobileMenuExplore,
        routeInfo: null,
        auth: false,
      },
      {
        title: 'LICENSING 授權說明',
        arrow: true,
        routeInfo: {
          name: 'licensing',
        },
        auth: false,
      },
      {
        title: 'CONTACT 聯絡我們',
        arrow: true,
        routeInfo: {
          name: 'front-contact',
        },
        auth: false,
      },
      {
        title: 'ABOUT US 關於我們',
        arrow: true,
        routeInfo: {
          name: 'front-about-us',
        },
        auth: false,
      },
    ];

    return {
      menuList,
      toPage,
      isLogin: computed(() => siteStore.isLogin),
    };
  },
});
</script>
<style lang="scss" scoped>
.item-content {
}

.title {
  @include flex(space-between);
  @include padding(0 20px);
  cursor: pointer;

  &__text {
    @include flex();
    @include font-style($c-black, 20, 700, 0, 27px);
    height: 42px;
  }

  &__icon {
    width: 7.5px;
  }
}

.extra-content {
  @include padding(0 20px 10px);
}

.divide {
  width: 100%;
  height: 1px;
  background-color: $c-assist7;
  margin: 24px 0;
}
</style>
