import { defineStore } from 'pinia';

// 避免同時有多個彈窗或展開彈出
const closeOtherPop = (self, closeList) => {
  closeList.forEach((statusStr) => {
    self[statusStr] = false;
  });
};

export const useSiteStore = defineStore({
  id: 'site',
  state: () => ({
    isLogin: false,
    memberLevel: 1,
    scrollOffset: 0,
    appRoot: null,
    scrollEle: null,
    backgroundImage: null,
    showSearchPop: false,
    showMobileMenuContent: false,
    showMobileMusicPlayer: false,
    showMobileFunction: false,
    deviceType: 'init',
    autoFocusInput: false,

  }),

  actions: {
    setIsLogin(status) {
      this.isLogin = status;
    },
    handleLogout() {
      this.isLogin = false;
      window.location.href = '/';
      // do something
    },
    setScrollOffset(status) {
      this.scrollOffset = status;
    },
    setAppRoot(ele) {
      this.appRoot = ele;
    },
    setScrollEle(ele) {
      this.scrollEle = ele;
    },
    setBackgroundImage(image) {
      this.backgroundImage = image;
    },
    toggleShowSearchPop(status) {
      this.showSearchPop = status;
      closeOtherPop(this, ['showMobileMenuContent', 'showMobileMusicPlayer', 'showMobileFunction']);
      console.log('do...', status);
      if (status) {
        this.autoFocusInput = true;
      } else {
        this.autoFocusInput = false;
      }
    },
    toggleShowMobileMenuContent(status) {
      this.showMobileMenuContent = status;
      closeOtherPop(this, ['showSearchPop', 'showMobileMusicPlayer', 'showMobileFunction']);
    },
    toggleShowMobileMusicPlayer(status) {
      this.showMobileMusicPlayer = status;
      closeOtherPop(this, ['showMobileMenuContent', 'showSearchPop', 'showMobileFunction']);
    },
    toggleShowMobileFunction(status) {
      console.log('toggleShowMobileFunction', status);
      this.showMobileFunction = status;
      closeOtherPop(this, ['showMobileMenuContent', 'showMobileMusicPlayer', 'showSearchPop']);
    },

    // setAutoFocusInput(status) {
    //   this.autoFocusInput = status;
    // },

    setDeviceType() {
      const width = document.body.clientWidth;

      const typeCollect = [];

      const rwdTyepMatching = {
        320: 'init',
        576: 'mobile',
        768: 'portrait',
        1024: 'tablet',
        1440: 'sm-pc',
        1600: 'pc',
        2560: 'lg-pc',
      };

      const widthList = [
        320,
        576,
        768,
        1024,
        1440,
        1600,
        2560,
      ];

      widthList.forEach((w) => {
        if (width >= w) {
          typeCollect.push(rwdTyepMatching[String(w)]);
        }
      });

      console.log('typeCollect', typeCollect);

      this.deviceType = typeCollect;
    },

  },

});

export const a = '';
