import { defineStore } from 'pinia';

export const useMusicPlayerStore = defineStore({
  id: 'musicPlayer',
  state: () => ({
    info: {
      albumCoverUrl: './static/musicPlayer/album-cover.jpeg',
      // albumCoverUrl: image,
      albumName: 'DISCO',
      songName: 'Drifting Away',
      singer: 'Instrumenal',
      creator: 'EN026-1',
    },
    controll: {
      playStatus: 'play', // 播放鍵狀態 播放中/暫停
      currentTime: '01:20', // 正在播放到的位置
      currentTimeLength: '80', // 正在播放到的位置換算秒數
      totalTime: '03:08', // 整首歌長度
      totalTimeLength: '189', // 整首歌長度換算秒數
      progressPersentage: '30.21', // 現在播放位置百分比
    },
    volume: {
      level: 1, // 揚聲器 icon 有三種圖案 mute,
      progressPersentage: '', // 現在播放位置百分比
    },
    track: {
      version: '7',
      queue: '15',
    },
    testing: 'aaa',
    footerBottomExtraPaddingType: 'circle',

  }),

  actions: {
    handlePlayStatus(status) {
      this.controll.playStatus = status;
    },
    handleVolumeLevel(level) {
      this.volume.level = level;
    },
    handlePrev() {
      console.log('handlePrev');
    },
    handleNext() {
      console.log('handleNext');
    },
    setFooterBottomExtraPaddingType(type) {
      this.footerBottomExtraPaddingType = type;
    },
  },
});

export const a = '';
